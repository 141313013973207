<script>
import EzInput from '@/components/ui/Input';

const Event = {
  onChange: 'onChange',
};

export default {
  name: 'VDormantInput',
  components: { EzInput },
  props: {
    name: {
      required: true,
      type: String,
    },
    formKey: {
      required: true,
      type: String,
    },
    value: {
      required: false,
      type: Number,
      default: 0,
    },
    title: {
      required: false,
      type: String,
      default: 'Dormant account notification',
    },
  },
  methods: {
    onChange(val) {
      this.$emit(Event.onChange, +val);
    },
  },
};
</script>

<template>
  <div class="dormant-input">
    <h4 class="dormant-input__title">{{ title }}</h4>
    <div class="dormant-input__info">
      <slot></slot>
    </div>
    <div class="u-flex-center dormant-input__box">
      <p class="dormant-input__label">
        Send a reminder after
      </p>
      <ez-input
        :form-key="formKey"
        type="number"
        :name="name"
        :value="value"
        min="0"
        :isTooltipError="true"
        @onChange="onChange"
      />
      <p class="dormant-input__label">{{ 'day' | pluralize(value) }}.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dormant-input {
  margin-bottom: 1rem;
}

.dormant-input__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.dormant-input__info {
  @include font-size(12px);
  color: $color-gray-6C;
  margin: 0;
  line-height: px-to-rem(18px);
}

.dormant-input__box {
  height: 36px;
  margin: 12px 0;

  :deep() .input-group {
    max-width: 56px;
    margin: 0 8px;
    text-align: right;
  }
}

.dormant-input__label {
  @include font-size(14px);
}
</style>
