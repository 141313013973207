<script>
import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form';
import { EzFormModal } from '@/components/ui/Modal';
import EzInput from '@/components/ui/Input';
import { LOADING_KEY, userModalTab1, userModalTab2, userModalTab3 } from '@/util/constants';
import { mapGetters, mapMutations } from 'vuex';
import uuid from 'uuid/v4';
import { EzTab, EzTabs } from '@/components/ui/Tabs';
import EzRolesList from '@/views/common/users/EzRolesList';
import { falsy, clone, debounce } from '@/util/utils';
import OrderNotificationToggle from '@/views/common/users/OrderNotificationToggle';
import EzOnOff from '@/components/ui/OnOff';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import EzToggle from '@/components/ui/Toggle';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import flash from '@/components/ui/FlashMessage';

export default {
  components: {
    EzButton,
    EzForm,
    EzFormModal,
    EzInput,
    EzTabs,
    EzTab,
    EzRolesList,
    OrderNotificationToggle,
    EzOnOff,
    VVenueEntityInfo,
    EzToggle,
    EzCheckbox,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    formAction: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    users: {
      type: Array,
      required: false,
      default: () => [],
    },
    venues: {
      type: Array,
      required: false,
      default: () => [],
    },
    additionalData: {
      type: Object,
      required: false,
      default: null,
    },
    entity: {
      type: String,
      required: false,
      default: 'User',
    },
    roles: {
      type: Array,
      default: () => [],
    },
    platform: {
      type: String,
      required: false,
      default: 'distributor',
    },
  },
  data() {
    return {
      keyId: null,
      loadingKey: LOADING_KEY.VENUE_SUPPLIER_ADD_USER,
      userModalTab1,
      userModalTab2,
      userModalTab3,
      distributorTabsFields: {
        [userModalTab1]: ['name', 'email', 'phone'],
        [userModalTab2]: ['roleKey'],
        [userModalTab3]: [],
      },
      venueTabsFields: {
        [userModalTab1]: ['name', 'email', 'phone', 'roleKey'],
        [userModalTab2]: ['venueId', 'venueIds'],
        [userModalTab3]: [],
      },
      settings: {
        email: false,
        whatsApp: false,
        sms: false,
      },
      allowGroupPermissions: false,
      selectedVenues: [],
      userPermissionsCopy: {},
      selectedRoleId: null,
      selectedRole: null,
      term: '',
      venuesListExpanded: false,
    };
  },
  computed: {
    ...mapGetters('errors', [
      'getFormErrors',
    ]),
    getErrors() {
      return this.getFormErrors(this.formKey) || {};
    },
    hasErrors() {
      return !falsy(this.getErrors);
    },
    defaultData() {
      return {
        optionalPermissions: [],
        ...this.additionalData,
      };
    },
    isExistingUser() {
      return this.user && !!this.user.id;
    },
    isAdmin() {
      if (!this.selectedRoleId) return this.user.role.key === 'venue_admin';
      return this.selectedRoleId === 'venue_admin';
    },
    isManager() {
      return this.selectedRoleId === 'venue_manager';
    },
    userPermissions() {
      return this.user.permissions || {};
    },
    method() {
      return this.isExistingUser ? 'patch' : 'post';
    },
    action() {
      if (!this.isExistingUser) {
        return this.formAction;
      }

      return `${this.formAction}/${this.user.id}`;
    },
    ...mapGetters('loading', ['getLoading']),
    isLoadingUser() {
      return this.getLoading(LOADING_KEY.VENUE_SUPPLIER_ADD_USER);
    },
    additionalPermissions() {
      const permissions = ((this.selectedRole || {}).optionalPermissions || []);
      const isUserPermissionsCopyEmpty = !Object.values(this.userPermissionsCopy).length;
      if (isUserPermissionsCopyEmpty) {
        permissions.forEach((per) => {
          this.changePermission(per.default, per);
        });
      }
      return permissions
        .filter(p => !p.parentKey || this.userPermissionsCopy[p.parentKey]);
    },
    filteredVenues() {
      const filtered = this.venues.filter(item => item.name.toLowerCase().includes(this.term.toLowerCase()));
      const filteredSelected = filtered.filter(item => this.selectedVenues.indexOf(item.id) >= 0);
      filteredSelected.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) return -1;
        if (textA > textB) return 1;
        return 0;
      });
      const filteredUnselected = filtered.filter(item => this.selectedVenues.indexOf(item.id) === -1);
      filteredUnselected.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) return -1;
        if (textA > textB) return 1;
        return 0;
      });
      return [...filteredSelected, ...filteredUnselected];
    },
    areAllSelected() {
      const unselected = this.filteredVenues.filter(item => this.selectedVenues.indexOf(item.id) === -1).length;
      return unselected === 0 && this.filteredVenues.length > 0;
    },
    areNoneSelected() {
      return this.selectedVenues.length === 0;
    },
    confirmButtonCopy() {
      if (this.platform !== 'distributor') return 'Save Changes';
      return this.isExistingUser ? 'Update Details' : `Add ${this.entity}`;
    },
    expandButtonCopy() {
      return this.venuesListExpanded ? 'Collapse List' : 'Show more outlets';
    },
    expandButtonIcon() {
      return this.venuesListExpanded ? 'angle-up' : 'angle-down';
    },
    numberOfVenuesToDisplay() {
      if (this.filteredVenues.length - 5 > 3) return 5;
      return this.filteredVenues.length;
    },
    filteredVenuesToDisplay() {
      if (!this.venuesListExpanded) {
        return this.filteredVenues.slice(0, this.numberOfVenuesToDisplay);
      }
      return this.filteredVenues;
    },
  },
  watch: {
    user(val) {
      this.user = val;
      this.keyId = uuid();
      this.settings = {
        ...this.user?.notificationSettings,
      };
      if (this.platform !== 'distributor') {
        this.selectedVenues = [];
        this.allowGroupPermissions = this.user.scope === 'venue-group';
        this.selectedRoleId = (this.user.role && this.user.role.key) ? this.user.role.key : null;
        this.selectedRole = this.roles.find(r => r.id === this.user.role.key);
        if (this.user.venues) this.user.venues.forEach(item => this.selectedVenues.push(item.id));
      }
    },
    hasErrors(val) {
      if (val) this.changeTab();
    },
  },
  methods: {
    ...mapMutations('errors', [
      'CLEAR_ERRORS',
    ]),
    open() {
      this.$refs.tabs.selectTab(userModalTab1);
      this.$refs.userModal.open();
    },
    reset() {
      this.selectedRole = null;
      this.allowGroupPermissions = false;
      this.selectedVenues = [];
      this.selectedRoleId = null;
      this.userPermissionsCopy = {};
      this.term = '';
      this.venuesListExpanded = false;
    },
    close() {
      this.$refs.userModal.close();
      if (this.platform !== 'distributor') this.reset();
    },
    onClose() {
      this.CLEAR_ERRORS({ key: this.formKey });
      if (this.platform === 'distributor') this.$refs.notifySettings.reset();
      else this.reset();
    },
    submitForm() {
      if (this.platform === 'venue'
        && this.areNoneSelected
        && !(this.isManager && this.allowGroupPermissions)
        && !this.isAdmin
      ) {
        flash.error({
          title: 'Please select an outlet.',
        });
        this.selectTab(userModalTab2);
      } else {
        this.$refs.form.onSubmit();
      }
    },
    onSubmitSuccess(result) {
      this.$emit('success', { ...result, isUpdate: this.isExistingUser });
      if (!this.isExistingUser) this.$refs.form.reset();
    },
    onSubmitError(result) {
      this.$emit('error', result);
    },
    checkError(tab) {
      const errors = Object.keys(this.getErrors);
      if (this.platform === 'distributor') return errors.some(err => this.distributorTabsFields[tab].includes(err));
      return errors.some(err => this.venueTabsFields[tab].includes(err));
    },
    selectTab(tab) {
      this.$refs.tabs.selectTab(tab);
    },
    changeTab() {
      if (this.hasErrors) {
        switch (true) {
          case this.checkError(userModalTab1):
            this.selectTab(userModalTab1);
            break;
          case this.checkError(userModalTab2):
            this.selectTab(userModalTab2);
            if (this.platform === 'venue') {
              flash.error({
                title: 'Please select an outlet.',
              });
            }
            break;
          case this.checkError(userModalTab3):
            this.selectTab(userModalTab3);
            break;
          default: this.selectTab(userModalTab1);
        }
      }
    },
    setNotifySettings(settings) {
      this.settings = {
        ...settings,
      };
    },
    transformer(data) {
      let tst = {};
      if (this.platform === 'distributor') {
        tst = {
          ...data,
          notificationSettings: { ...this.settings },
        };
      } else {
        if ((this.allowGroupPermissions && this.isManager) || this.isAdmin) {
          this.selectedVenues = this.venues.map(item => item.$id);
        }
        tst = {
          ...data,
          ...((this.allowGroupPermissions && this.isManager) ? { venueIds: null } : { venueIds: this.selectedVenues }),
        };
      }
      return tst;
    },
    onRoleChange(permissions, role) {
      this.selectedRoleId = role.id;
      this.selectedRole = this.roles.find(r => r.id === role.id);
      this.allowGroupPermissions = this.user.scope === 'venue-group';
    },
    onGroupPermissionChange(val) {
      this.allowGroupPermissions = val;
    },
    toggleSelectedVenues(venue) {
      if (this.selectedVenues.indexOf(venue.id) === -1) this.selectedVenues.push(venue.id);
      else this.selectedVenues = this.selectedVenues.filter(item => item !== venue.id);
    },
    changePermission(ev, perm) {
      this.userPermissionsCopy[perm.key] = ev;
      this.userPermissionsCopy = clone(this.userPermissionsCopy);
    },
    onSearch: debounce(function deb(term) {
      if (term !== this.term) {
        this.term = term;
      }
    }, 300),
    onSelectAll(event) {
      if (event) {
        this.selectedVenues = this.filteredVenues.map(item => item.id);
      } else {
        const unselectedVenueIds = this.filteredVenues.map(item => item.id);
        this.selectedVenues = this.selectedVenues.filter(item => unselectedVenueIds.indexOf(item) === -1);
      }
    },
    toggleListExpanded() {
      this.venuesListExpanded = !this.venuesListExpanded;
    },
  },
};
</script>

<template>
  <ez-form-modal
    @close="onClose"
    ref="userModal"
    class="ez-user-modal">
    <template #title>
      <slot name="title">{{ isExistingUser ? `${entity} Details` : `Add New ${entity}` }}</slot>
    </template>
    <template #content>
      <ez-form
        :key="keyId"
        ref="form"
        :formKey="formKey"
        :additional-headers="{ loadingKey }"
        :action="action"
        :method="method"
        :default-data="defaultData"
        :transformer="transformer"
        @success="onSubmitSuccess"
        @error="onSubmitError">
        <ez-tabs ref="tabs">
          <ez-tab v-if="platform === 'distributor'" :title="`${entity} Info`" :hash="userModalTab1">
            <ez-input
              class="mt-16"
              :formKey="formKey"
              label="Name"
              name="name"
              placeholder="Enter Full Name"
              :value="user.name" />
            <ez-input
              :formKey="formKey"
              label="Email Address"
              placeholder="Enter Email Address"
              name="email"
              :value="user.email" />
            <ez-input
              class="mt-12"
              :formKey="formKey"
              :value="user.phone || ''"
              placeholder="Enter Phone Number"
              name="phone"
              label="Phone Number" />
          </ez-tab>
          <ez-tab v-else :title="`${entity} Details`" :hash="userModalTab1">
            <ez-input
              class="mt-16"
              :formKey="formKey"
              label="Name"
              name="name"
              placeholder="Enter Full Name"
              :value="user.name" />
            <ez-input
              :formKey="formKey"
              label="Email Address"
              placeholder="Enter Email Address"
              name="email"
              :value="user.email" />
            <ez-input
              class="mt-12"
              :formKey="formKey"
              :value="user.phone || ''"
              placeholder="Enter Phone Number"
              name="phone"
              label="Phone Number" />
            <hr class="mb-0">
            <EzRolesList
              ref="roles"
              :formKey="formKey"
              :user="user"
              :roles="roles"
              :venues="venues"
              detachPermissions
              @roleChange="onRoleChange"
              @groupPermissionChange="onGroupPermissionChange"
            />
          </ez-tab>
          <ez-tab v-if="platform === 'distributor'" title="Role & Permissions" :hash="userModalTab2">
            <EzRolesList
              ref="roles"
              :formKey="formKey"
              :user="user"
              :roles="roles"
              :users="users"
            />
          </ez-tab>
          <ez-tab v-else title="Outlet Assignment" :hash="userModalTab2">
            <ez-input
              formKey=""
              name="search"
              class="search mt-16"
              placeholder="Search for an outlet"
              label="Outlets"
              :value="term"
              @onChange="onSearch"
            >
              <template #suffix>
                <font-awesome-icon icon="search" />
              </template>
            </ez-input>
            <ez-checkbox
              v-if="!((isManager && allowGroupPermissions) || isAdmin)"
              class="mt-24"
              label="Select All"
              :checked="areAllSelected"
              @change="onSelectAll($event)"
            />
            <ul class="ez-special-tier-form__venue-list">
              <li v-for="venue in filteredVenuesToDisplay" :key="venue.id">
                <div class="ez-special-tier-form__venue-list-item">
                  <v-venue-entity-info
                    class=""
                    :venue="venue"
                    :title="venue.name"
                  />
                  <ez-toggle
                    class="outlet-toggle ml-12"
                    @toggleState="toggleSelectedVenues(venue)"
                    :isActive="selectedVenues.includes(venue.id) || (isManager && allowGroupPermissions) || isAdmin"
                    :disabled="(isManager && allowGroupPermissions) || isAdmin"
                  >
                    <template #active><span>Selected</span></template>
                    <template #inactive><span>Select</span></template>
                  </ez-toggle>
                </div>
              </li>
            </ul>
            <ez-button
              v-if="filteredVenues.length > numberOfVenuesToDisplay"
              class="u-flex-h-center mt-16"
              @click="toggleListExpanded"
              isFullWidth
              type="link"
              formType="button"
            >
              <span>{{ expandButtonCopy }}</span>
              <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
            </ez-button>
          </ez-tab>
          <ez-tab v-if="platform === 'distributor'" title="Order Notifications" :hash="userModalTab3">
            <order-notification-toggle
              :user="user"
              ref="notifySettings"
              :form-key="formKey"
              @change="setNotifySettings"
            />
          </ez-tab>
          <ez-tab v-else title="Permissions" :hash="userModalTab3">
            <div class="ez-permissions mt-16">
              <div class="ez-permissions__label">Permissions</div>
              <ul>
                <!-- <li v-for="permission in [...additionalPermissions, ...updatedAdditionalPermissions]" :key="permission.id"> -->
                <li v-for="permission in additionalPermissions" :key="permission.id">

                  <div class="ez-permissions__permission"
                  >
                    <div class="ez-permissions__label-container">
                      <span>{{ permission.name }}</span>
                      <small>{{ permission.description }}</small>
                    </div>
                    <ez-on-off
                      :formKey="formKey"
                      :checked="isExistingUser ? !!userPermissions[permission.key] : permission.default"
                      :value="permission.key"
                      @change="(ev) => changePermission(ev, permission)"
                      name="optionalPermissions"/>
                  </div>
                </li>
              </ul>
            </div>
          </ez-tab>
        </ez-tabs>
      </ez-form>
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close">Cancel</ez-button>
      <ez-button
        formType="button"
        @click="submitForm"
        :is-loading="isLoadingUser"
      >
        {{ confirmButtonCopy }}
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style scoped lang="scss">
.ez-user-modal {
  :deep() .ez-dropdown {
    max-width: 100%;
  }
  :deep() .modal {
    overflow: visible;
  }
  .ez-form {
    .input-group + .input-group {
      margin-top: .75rem;
    }
  }
}
:deep() .ez-tabs {
  &__buttons {
    & > li:first-child {
      button {
        margin-left: 0;
      }
    }
  }
  &__content {
    overflow: inherit;
  }
}
:deep() .role-dropdown {
  border-top: none;
  margin-top: 0;
  padding-top: 16px;
}

.ez-permissions {
  ul {
    @extend %ul-reset;
    li {
      + li {
        margin-top: 1rem;
      }
    }
  }
  &__permission {
    display: flex;
    align-self: flex-start;
    justify-content: space-between;
  }
  &__label-container {
    display: flex;
    flex-direction: column;
    span {
      @include font-size(14px, 16px);
      font-weight: 400;
      margin-bottom: .25rem;
    }
    small {
      @include font-size(12px, 16px);
      color: $color-gray-6C;
    }
  }
  &__label {
    @extend %gray-label;
    margin-bottom: .75rem;
  }
}

.ez-special-tier-form__venue-entity {
  max-width: 315px;
}

.ez-special-tier-form {
  .search-input {
    margin-bottom: $spacing-12;

    &__icon {
      margin-left: $spacing-12;
      color: $color-gray-6C;
    }
  }

  &__venue-list-item :deep() {
    margin-top: $spacing-08;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    width: 100%;
  }

  &__venue-list {
    @extend %ul-reset;
  }
}

:deep() .outlet-toggle.ez-toggle .ez-toggle__button {
  padding: 10px 12px;
  font-weight: normal;
  height: 36px;
  width: 108px;
  margin: 0 auto;
}
</style>
