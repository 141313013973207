<template>
  <ez-select
    ref="select"
    class="input-group"
    :name="name"
    :disabled="disabled"
    :isFullWidth="isFullWidth"
    :value="selected"
    :selected="selected"
    :label="label"
    :options="categories"
    @change="onChange"
    :data-cy="dataCy"
  >
    <ul>
      <li
        v-for="option in data"
        :key="option.id"
      >
        <ez-option
          :disabled="isParentDisabled"
          class="parent"
          :option="option"
        >
          {{ option.name }}
        </ez-option>

        <ul v-if="option.children && option.children.length">
          <li
            v-for="child in option.children"
            :key="child.id">
            <ez-option :option="child">{{ child.name }}</ez-option>
          </li>
        </ul>
      </li>
    </ul>
  </ez-select>
</template>

<script>
import EzSelect, { EzOption } from '@/components/ui/Select';

export default {
  components: {
    EzSelect,
    EzOption,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    selected: {
      type: [Number, String],
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    isParentDisabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    categories() {
      const categories = [];
      this.data.forEach((category) => {
        const { children, ...cat } = category;
        categories.push(cat);
        if (children) categories.push(...children);
      });
      return categories;
    },
  },
  methods: {
    sortByName(a, b) {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      // eslint-disable-next-line
        return aName !== bName ? aName < bName ? -1 : 1 : 0;
    },
    onChange(value) {
      this.$emit('change', value);
    },
    reset() {
      this.$refs.select.reset();
    },
  },
};
</script>

<style scoped lang="scss">
  :deep() .ez-select__display-container {
    height: 2.25rem;
  }
  .ez-select__dropdown {
    li ul .ez-option {
      padding-left: px-to-rem(24px);
    }
  }

  .parent { font-weight: bold; }
</style>
