<template functional>
  <div class="ez-empty-state" :class="data.staticClass || ''" v-bind="data.attrs">
    <div class="ez-empty-state__image">
      <slot name="badge"><div class="ez-empty-state__circle"></div></slot>
    </div>
    <h3><slot name="title"></slot></h3>
    <p><slot name="info"></slot></p>
    <slot/>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.ez-empty-state {
  @extend %flex-center;
  flex-direction: column;
  &__circle {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: #CBD1DF;
    margin-bottom: 1rem;
  }
  &__image { max-width: 160px; }
  h3 {
    @include font-size(20px,24px);
    font-weight: 500;
    margin: 1rem 0 .5rem 0;
  }
  p {
    margin: 0;
    max-width: 22.5rem;
    text-align: center;
    color: #6C7995;
  }
}
</style>
