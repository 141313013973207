<template>
  <single-page :entity="distributor">
    <template #breadcrumbs>
      <router-link :to="{name: 'admin-distributors'}">
        <font-awesome-icon icon="arrow-left"/>
        Back to {{ $t('global.distributors') }}
      </router-link>
    </template>
    <template #title>
      <v-entity-title :entity="distributor" />
    </template>
    <template #actions>
      <span
        v-tooltip.bottom="isSendInviteDisabled ?
        {
          content: 'You need at least one user to be able to send an invite',
          classes: ['tooltip-general']
         } :
        {}">
        <ez-button
          v-if="canSendInvite"
          :disabled="isSendInviteDisabled"
          type="primary"
          customClass="invitation-button"
          @click="sendInvitation">
            <font-awesome-icon icon="paper-plane"></font-awesome-icon>
            {{ invitationCta }}
        </ez-button>
      </span>
      <ez-button-dropdown buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h"/>
        </template>
        <template #dropdown>
          <ez-button
            @click="onDeleteDistributor"
            type="red-link">Delete {{ $t('global.distributor') }}</ez-button>
        </template>
      </ez-button-dropdown>
    </template>
    <template #navigation>
      <ul>
        <li>
          <router-link
            :to="{ name: 'admin-distributor-info' }">
            {{ $t('global.distributor') }} Info
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'admin-distributor-venues' }">
            Connected {{ $t('global.venues') }}
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'admin-distributor-products' }">Products</router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'admin-distributor-users' }">Users</router-link>
        </li>
      </ul>
    </template>

    <ez-confirmation-modal ref="deleteModal" type="red">
      <template #title>Delete this {{ $t('global.distributor') }}?</template>
      <template #content>
        <p>When deleted, this distributor will no longer be live on {{ platformName }}
          and {{ $t('global.venues') | lowercase }}
          connected to it won’t no longer see it’s products.</p>

        <p>You won’t see this {{ $t('global.distributor') | lowercase }}
          in admin panel as well.</p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="confirmDeleteDistributor" type="red">Yes, Delete</ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-loader :show="isLoading">Loading...</ez-loader>
  </single-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACCOUNT_STATUS_INVITE_SENT, ACCOUNT_STATUS_DRAFT, LOADING_KEY } from '@/util/constants';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import EzButton from '@/components/ui/Button';
import VEntityTitle from '@/components/v3/patterns/VEntityTitle';
import SinglePage from '@/views/admin/SinglePage.vue';

export default {
  components: {
    EzButton,
    EzButtonDropdown,
    EzConfirmationModal,
    VEntityTitle,
    SinglePage,
    EzLoader,
  },
  props: {
    distributorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      distributor: {},
      users: [],
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR,
        LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR_USERS,
      ]);
    },
    canSendInvite() {
      return [ACCOUNT_STATUS_INVITE_SENT, ACCOUNT_STATUS_DRAFT].includes(this.distributor.status);
    },
    isSendInviteDisabled() {
      return !this.users.length;
    },
    invitationCta() {
      return this.distributor.status === ACCOUNT_STATUS_DRAFT ? 'Send Invite' : 'Resend Invite';
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    sendInvitation() {
      const { distributorId } = this;

      this.sendDistributorInvitation(distributorId);
    },
    onDeleteDistributor() {
      this.$refs.deleteModal.open();
    },
    confirmDeleteDistributor() {
      const { distributorId } = this;

      this.removeDistributor(distributorId).then(() => {
        this.closeDeleteModal();

        this.$router.push({
          name: 'admin-distributors',
          params: {
            flash: {
              type: 'success',
              title: 'Distributor successfully deleted!',
            },
          },
        });
      });
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
    },
    ...mapActions('entities/distributors', [
      'fetchAdminDistributor',
      'sendDistributorInvitation',
      'removeDistributor',
    ]),
    ...mapActions('entities/users', ['fetchAdminDistributorUsersNew']),
  },
  async mounted() {
    const [{ data: distributorData }, { data: usersData }] = await Promise.all([
      this.fetchAdminDistributor({ distributorId: this.distributorId }),
      this.fetchAdminDistributorUsersNew({ distributorId: this.distributorId }),
    ]);
    this.distributor = distributorData.data;
    this.users = usersData.data;
  },
};
</script>
