import bus from './bus';

export const WIZARD_GO_TO_STEP = 'wizardGoToStep';
export const WIZARD_NEXT_STEP = 'wizardNextStep';
export const WIZARD_PREVIOUS_STEP = 'wizardPreviousStep';
export const WIZARD_ENABLE_NEXT_STEP = 'wizardEnableNext';
export const WIZARD_DISABLE_NEXT_STEP = 'wizardDisableNext';
export const WIZARD_ENABLE_DOWNLOAD_ACTIONS = 'wizardEnableDownloadActions';
export const WIZARD_DISABLE_DOWNLOAD_ACTIONS = 'wizardDisableDownloadActions';
export const WIZARD_DOWNLOAD_ACTION = 'wizardDownloadAction';

export const wizardEmitterMixin = {
  data() {
    return {
      nextDisabled: false,
      footerActions: false,
    };
  },
  methods: {
    goToStep({ step, payload }) {
      bus.$emit(WIZARD_GO_TO_STEP, { step, payload });
    },
    goToNextStep() {
      bus.$emit(WIZARD_NEXT_STEP);
    },
    goToPreviousStep() {
      bus.$emit(WIZARD_PREVIOUS_STEP);
    },
    downloadTosPdf() {
      bus.$emit(WIZARD_DOWNLOAD_ACTION);
    },
    enableNextStep() { this.nextDisabled = false; },
    disableNextStep() { this.nextDisabled = true; },
    enableFooterActions() { this.footerActions = true; },
    disableFooterActions() { this.footerActions = false; },
  },
  mounted() {
    bus.$on(WIZARD_ENABLE_NEXT_STEP, this.enableNextStep);
    bus.$on(WIZARD_DISABLE_NEXT_STEP, this.disableNextStep);
    bus.$on(WIZARD_ENABLE_DOWNLOAD_ACTIONS, this.enableFooterActions);
    bus.$on(WIZARD_DISABLE_DOWNLOAD_ACTIONS, this.disableFooterActions);
  },
  beforeDestroy() {
    bus.$off(WIZARD_ENABLE_NEXT_STEP, this.enableNextStep);
    bus.$off(WIZARD_DISABLE_NEXT_STEP, this.disableNextStep);
    bus.$off(WIZARD_ENABLE_DOWNLOAD_ACTIONS, this.enableFooterActions);
    bus.$off(WIZARD_DISABLE_DOWNLOAD_ACTIONS, this.disableFooterActions);
  },
};

export const wizardListenerMixin = {
  methods: {
    // eslint-disable-next-line no-unused-vars
    onGoToStep({ step, payload }) {},
    onNextStep() {},
    onPreviousStep() {},
    onDownloadTosPdf() {},
    enableNextStep() { bus.$emit(WIZARD_ENABLE_NEXT_STEP); },
    disableNextStep() { bus.$emit(WIZARD_DISABLE_NEXT_STEP); },
    enableFooterActions() { bus.$emit(WIZARD_ENABLE_DOWNLOAD_ACTIONS); },
    disableFooterActions() { bus.$emit(WIZARD_DISABLE_DOWNLOAD_ACTIONS); },
  },
  mounted() {
    bus.$on(WIZARD_GO_TO_STEP, this.onGoToStep);
    bus.$on(WIZARD_NEXT_STEP, this.onNextStep);
    bus.$on(WIZARD_PREVIOUS_STEP, this.onPreviousStep);
    bus.$on(WIZARD_DOWNLOAD_ACTION, this.onDownloadTosPdf);
  },
  beforeDestroy() {
    bus.$off(WIZARD_GO_TO_STEP, this.onGoToStep);
    bus.$off(WIZARD_NEXT_STEP, this.onNextStep);
    bus.$off(WIZARD_PREVIOUS_STEP, this.onPreviousStep);
    bus.$off(WIZARD_DOWNLOAD_ACTION, this.onDownloadTosPdf);
  },
};
