<template>
  <div class="admin-distributor-products">
    <section class="admin-distributor-products__actions">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="product-search"
          name="search"
          label="Search"
          placeholder="Search for a Product"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
        <ez-category-filter name="categoryId" />
      </ez-filter-list>

      <div class="admin-distributor-products__buttons">
        <ez-button-dropdown :showToggleIcon="false" :expandOnClick="true" buttonType="secondary">
          <template>
            <span>Import/Export CSV</span>
          </template>
          <template #dropdown>
            <ez-button
              type="link"
              formType="button"
              customClass="upload-csv"
              @click="uploadCSVModal"
            >
              Upload CSV
            </ez-button>
            <ez-button type="link" formType="button" customClass="upload-csv" @click="exportCSV">
              Export CSV
            </ez-button>
          </template>
        </ez-button-dropdown>
        <router-link
          v-if="distributorId"
          :to="{
            name: 'admin-distributors-products-new',
            params: {
              distributorId: distributorId,
            },
          }"
          class="button button--primary"
        >
          <span>Add New Product</span>
        </router-link>
      </div>
    </section>

    <div v-if="products.length">
      <ez-table
        @rowClick="editProduct"
        :data="products"
        :columns="['name', 'sku', 'category', 'importType', 'price']"
        :columnProps="{
          sku: { class: 'sku-cell' },
          category: { class: 'category-cell' },
          importType: { class: 'extra-large-cell' },
          price: { class: 'price-cell u-text-right' },
        }"
        :headers="{
          name: () => 'Product',
          price: () => 'Price per unit',
        }"
      >
        <template #cell-name="{ row: { image, name, category, sku } }">
          <ez-entity-info :imgUrl="image" :imgHasBorder="true">
            <div class="product-info">
              <span class="product-info__name" :title="name">
                {{ name }}
              </span>
              <span class="product-info-secondary">
                {{ sku }} &#8226; {{ category | categoryWithParent }}
              </span>
            </div>
          </ez-entity-info>
        </template>
        <template #cell-category="{ row: { category } }">
          <span class="category-badge">{{ category | categoryWithParent }}</span>
        </template>
        <template #cell-importType="{ row: { importType } }">
          <span>
            {{
              importType === 'trade_gecko' ? $t('quickBooks.nameShort') : importType | capitalize
            }}
          </span>
        </template>
        <template #cell-price="{ row: { price, currency } }">
          <div class="product-price">{{ price | price(currency) }}</div>
        </template>
      </ez-table>

      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="fetchMoreProducts" />
    </div>

    <empty-state v-else-if="hasFilters && !isLoading">
      <template #badge><img src="@/assets/no-products-search-state.svg" alt="" /></template>
      <template #title>No products match this search</template>
      <template #info>Try with a different search.</template>
    </empty-state>

    <empty-state v-else-if="!isLoading">
      <template #badge><img src="@/assets/no-product-empty-state.svg" alt="" /></template>
      <template #title>No products listed</template>
      <template #info>
        You can add products by uploading a CSV file, or add one product at a time.
      </template>
    </empty-state>

    <ez-csv-upload-modal
      ref="csvUploadModal"
      :uploadCSV="uploadAction"
      :onUploadFinished="onUploadFinished"
      :distributor-id="distributorId"
    />

    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import downloadAttachment from '@/util/downloadAttachment';
import { LOADING_KEY } from '@/util/constants';
import { EzCsvUploadModal } from '@/components/ui/Modal';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzFilterList from '@/components/ui/FilterList';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import EzButton from '@/components/ui/Button';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzInput from '@/components/ui/Input';
import EzTable from '@/components/ui/Table';
import EmptyState from '@/views/common/empty-state';

export default {
  components: {
    EzLoader,
    EzButton,
    EzCategoryFilter,
    EzCsvUploadModal,
    EzEntityInfo,
    EzButtonDropdown,
    EzFilterList,
    EzInput,
    EzLoadMore,
    EmptyState,
    EzTable,
    EzSpinner,
  },
  props: {
    distributorId: {
      type: Number,
      required: true,
    },
    entity: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      products: [],
      meta: {},
      filters: {
        term: null,
        categoryId: null,
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR_PRODUCTS,
        LOADING_KEY.ADMIN_EXPORT_DISTRIBUTOR_PRODUCTS,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ADMIN_DISTRIBUTOR_PRODUCTS);
    },
    distributor() {
      return this.entity;
    },
    hasFilters() {
      return Object.entries(this.filters).reduce((acc, [, value]) => acc || !!value, false);
    },
  },
  methods: {
    ...mapActions('entities/distributors', ['removeProductForDistributor']),
    ...mapActions('entities/products', [
      'uploadDistributorCSVAdmin',
      'adminDownloadDistributorProductsCSV',
      'fetchAdminDistributorProducts',
    ]),
    async fetchProducts(loadingKey = LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR_PRODUCTS, query = {}) {
      const payload = {
        distributorId: this.distributorId,
        query: {
          ...(this.meta.nextId && { nextId: this.meta.nextId }),
          ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
          limit: 20,
          sortBy: 'name',
          ...this.filters,
          ...query,
        },
        loadingKey,
      };
      const { data } = await this.fetchAdminDistributorProducts(payload);
      this.products = [...this.products, ...data.data];
      this.meta = data.meta;
    },
    async fetchMoreProducts() {
      await this.fetchProducts(LOADING_KEY.FETCH_MORE_ADMIN_DISTRIBUTOR_PRODUCTS);
    },
    async refreshProducts() {
      this.products = [];
      this.meta = {};
      await this.fetchProducts();
    },
    uploadAction(data) {
      return this.uploadDistributorCSVAdmin({
        id: this.distributorId,
        data,
      });
    },
    uploadCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    async exportCSV() {
      const { distributorId } = this;
      const { data } = await this.adminDownloadDistributorProductsCSV({ id: distributorId });
      downloadAttachment(data, `${this.distributor.name}'s products.xlsx`);
    },
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });

      await this.refreshProducts();
    },
    editProduct({ id }) {
      this.$router.push({
        name: 'admin-distributor-product-info',
        params: {
          productId: id,
          distributorId: this.distributorId,
        },
      });
    },
    async updateFilters(filterName, event) {
      if (filterName === 'search') {
        this.filters = {
          ...this.filters,
          term: event,
        };
      } else {
        this.filters = {
          ...this.filters,
          [filterName]: event.id,
        };
      }

      await this.refreshProducts();
    },
    onUploadFinished() {
      this.resetFilters();
    },
  },
  async mounted() {
    await this.fetchProducts();
  },
};
</script>

<style scoped lang="scss">
$table-border-radius: 0.6em;
$not-listed-text-color: #252631;
$not-listed-border-color: rgba(117, 128, 141, 0.2);
$search-icon-color: #8790a3;
$button-icon-color: #4d7cfe;

.admin-distributor-products {
  @include font-size(14px);
  display: flex;
  flex-direction: column;

  &__actions {
    display: flex;
    margin-bottom: 1.5rem;
  }

  &__buttons {
    margin-left: auto;

    .upload-csv {
      width: 8rem;
    }

    a.button {
      @extend %base-button;

      @include button-type(
        'primary',
        $button-primary-bg,
        $button-primary-color,
        $button-primary-hover-bg,
        $button-primary-bg
      );

      text-decoration: none;
      margin-left: 1rem;

      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .ez-filter-list {
    flex: 1 0 auto;
  }

  .product-info {
    @include name-status-group();
    justify-content: center;
  }

  .product-info-secondary {
    display: none;
  }

  @media (max-width: 1200px) {
    .product-info-secondary {
      display: block;
    }

    :deep() .table {
      .sku-cell,
      .category-cell {
        display: none;
      }
    }
  }

  @media (max-width: 1023px) {
    .product-info-secondary {
      display: none;
    }
  }

  .table {
    .category-badge {
      @extend %category-badge;
    }

    .product-price {
      text-align: right;
      color: $button-icon-color;
      font-weight: bold;
    }
  }

  .ez-load-more {
    margin-top: 2em;
  }
}

.upload-csv-modal {
  position: relative;
  padding: 48px;
}

:deep() .ez-empty-state {
  margin-top: 5rem;

  &__image {
    max-width: unset;

    img {
      width: 256px;
      height: 118px;
    }
  }
}

.checklist {
  margin-top: 1em;
  margin-bottom: 2.4em;
  @extend %ul-reset;

  li {
    @extend %flex-center;
    margin-bottom: 0.8em;
  }

  &__icon {
    margin-right: 0.5em;
    @include font-size(22px);
    color: #34b384;
  }
}

.csv-stats {
  @extend %ul-reset;
  margin-bottom: 3.4em;

  li {
    @extend %flex-center;
    justify-content: space-between;
    padding: 1.7em 0;
    border-bottom: 1px solid #dee1e4;
  }

  strong {
    color: #8790a3;
    flex-shrink: 0;
  }

  span {
    flex: 1 1 auto;
    margin-left: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
  }
}

.drawer {
  .drawer-title {
    @extend %flex-center;
    @include font-size(14px);

    &__import-type {
      div {
        @include font-size(10px);
        text-transform: uppercase;
        padding: 0 0.5em;
        background-color: #eff0f2;
        color: #858fa1;
        margin-left: 1em;
        border-radius: 0.2em;
      }
    }
  }
}

.modal__close {
  @include absolute(top 1em right 1em);
  @include font-size(22px);
  color: #858fa1;
}
</style>
