<template>
  <section v-show="isActive">
    <slot></slot>
  </section>
</template>
<script>
/**
 * Single Tab component used to wrap other components
 *
 * @version 1.0.0
 */
export default {
  props: {
    /**
     * Accepts text to render in the tab navigation (rendering is done by the Tabs component).
     * Also, accepts function to support custom html or Vue component rendering
     */
    title: {
      type: [
        Function,
        String,
      ],
    },
    /**
     * Hash that should be applied when tab is active
     */
    hash: {
      type: String,
      default: '',
    },
    /**
     * Exclude the tab from showing in the tab list
     */
    exclude: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>
