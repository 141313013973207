<template>
  <modal-with-tabs
    ref="userModal"
    :formKey="formKey"
    :formAction="formAction"
    :user="user"
    :users="users"
    :roles="roles"
    @success="onSubmitSuccess"
  >
  </modal-with-tabs>
</template>

<script>
import User from '@/models/User';
import flash from '@/components/ui/FlashMessage';
import { isAdmin } from '@/util/constants';
import ModalWithTabs from '@/views/common/users/ModalWithTabs';

export default {
  components: {
    ModalWithTabs,
  },
  props: {
    distributorId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
    },
    roles: {
      type: Array,
    },
    selectedUser: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      formKey: 'distributor-user',
    };
  },
  computed: {
    formAction() {
      if (isAdmin()) {
        return `/admin/distributors/${this.distributorId}/users`;
      }

      return '/distributor/users';
    },
    user() {
      const defaultUser = {
        role: { key: null },
        distributor: {},
        permissions: {},
      };

      return User.query()
        .with('distributor')
        .find(this.userId) || this.selectedUser || defaultUser;
    },
    users() {
      if (isAdmin()) {
        return User.query()
          .where('distributorId', this.distributorId)
          .all();
      }
      return User.all();
    },
  },
  methods: {
    open() {
      this.$refs.userModal.open();
    },
    close() {
      this.$refs.userModal.close();
    },
    onSubmitSuccess({ data, isUpdate }) {
      User.insertOrUpdate({ data: { ...data.data, distributorId: this.distributorId } });
      flash.success({ title: `User successfully ${isUpdate ? 'updated' : 'added'}.` });
      this.$emit('submitSuccess', data);
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
.ez-distributor-manager-permissions {
  @include separator();
  ul {
    @extend %ul-reset;
    li {
      display: flex;
      align-self: flex-start;
      justify-content: space-between;
      + li {
        margin-top: 1rem;
      }
    }
  }
  &__label-container {
    display: flex;
    flex-direction: column;
    span {
      @include font-size(14px, 16px);
      font-weight: 400;
      margin-bottom: .25rem;
    }
    small {
      @include font-size(12px, 18px);
      color: $color-gray-6C;
    }
  }
  &__label {
    @extend %gray-label;
    margin-bottom: .75rem;
  }
}
</style>
