<script>
import EzInput from '@/components/ui/Input/EzInput';
import Distributor from '@/models/Distributor';
import EzCheckbox from '@/components/ui/Checkbox';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'index',
  components: {
    EzInput,
    EzCheckbox,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    distributorData: {
      type: Object,
      required: false,
      default: () => {},
    },
    distributorId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      defaultCount: 0,
      valid: false,
    };
  },
  computed: {
    distributor() {
      return this.distributorData || Distributor.find(this.distributorId) || {};
    },
    workingHours() {
      return this.distributor.workingHours || {};
    },
    cutOffCount() {
      if (this.distributor.deliveryPeriodCount === null) return this.defaultCount;
      return Number(this.distributor.deliveryPeriodCount) >= 0
        ? this.distributor.deliveryPeriodCount
        : this.defaultCount;
    },
    deliveryDays() {
      return [
        {
          name: 'Sunday',
          value: 'Sunday',
          checked: this.distributor.deliveryDays?.includes('Sunday') || false,
        },
        {
          name: 'Monday',
          value: 'Monday',
          checked: this.distributor.deliveryDays?.includes('Monday') || false,
        },
        {
          name: 'Tuesday',
          value: 'Tuesday',
          checked: this.distributor.deliveryDays?.includes('Tuesday') || false,
        },
        {
          name: 'Wednesday',
          value: 'Wednesday',
          checked: this.distributor.deliveryDays?.includes('Wednesday') || false,
        },
        {
          name: 'Thursday',
          value: 'Thursday',
          checked: this.distributor.deliveryDays?.includes('Thursday') || false,
        },
        {
          name: 'Friday',
          value: 'Friday',
          checked: this.distributor.deliveryDays?.includes('Friday') || false,
        },
        {
          name: 'Saturday',
          value: 'Saturday',
          checked: this.distributor.deliveryDays?.includes('Saturday') || false,
        },
      ];
    },
  },
  methods: {
    validateInputs(name, value) {
      if (name === 'deliveryPeriodCount') {
        this.valid = +value >= 0;
      }
    },
    changePeriod(val) {
      this.validateInputs('deliveryPeriodCount', val);
    },
    onDeliveryDayChange(val, day) {
      const index = this.deliveryDays.findIndex(item => item.name === day);
      this.deliveryDays[index].checked = val;
    },
  },
  watch: {
    valid(val) {
      this.$emit('cutOffTimeValid', val);
    },
  },
  created() {
    const value =
      typeof this.distributor.deliveryPeriodCount === 'number'
        ? this.distributor.deliveryPeriodCount
        : this.defaultCount;
    this.validateInputs('deliveryPeriodCount', value);
  },
};
</script>

<template>
  <div>
    <div class="form-help">
      <h4>Cut-Off Time</h4>
      <p>Set when will the orders received by the cut-off time be delivered.</p>
    </div>

    <div class="cut-off-time">
      <div class="cut-off-time__row">
        <span>Orders must be received by</span>
        <ez-input
          class="ml-8 working-hours"
          :formKey="formKey"
          type="time"
          :value="workingHours.to || ''"
          is-tooltip-error
          name="workingHours[to]"
        />
      </div>
      <div class="cut-off-time__row">
        <div class="cut-off-time__row--inline">
          <ez-input
            class="delivery-period-count"
            :formKey="formKey"
            type="number"
            is-tooltip-error
            name="deliveryPeriodCount"
            :min="0"
            :value="cutOffCount"
            validators="min:0"
            @onChange="changePeriod"
          />
          <span class="ml-8">day(s) before delivery day</span>
        </div>
      </div>
      <div class="cut-off-time__row cut-off-time__row--block">
        <span>Delivery Days</span>
        <div class="delivery-days mt-12" :formKey="formKey">
          <ez-checkbox
            class="mb-16"
            v-for="day in deliveryDays"
            :key="day.name"
            :checked="day.checked"
            name="deliveryDay"
            :label="day.name"
            :value="day.name"
            @change="onDeliveryDayChange($event, day.name)"
          />
        </div>
      </div>
    </div>

    <hr class="address-separator mt-8" />
  </div>
</template>

<style lang="scss" scoped>
.form-help {
  margin-bottom: 1rem;

  h4 {
    margin-top: 0;
    margin-bottom: 8px;
    @include font-size(14px);
    font-weight: 500;
  }

  p {
    @include font-size(12px);
    color: $color-gray-6C;
    margin: 0;
    line-height: px-to-rem(18px);
  }
}
.cut-off-time {
  display: flex;
  flex-direction: column;
  &__row {
    @extend %flex-center;
    & + & {
      margin-top: 12px;
    }
    &--block {
      display: block;
    }
    span {
      flex-shrink: 0;
      @include font-size(14px, 20px);
      color: $color-gray-6C;
    }
    .working-hours {
      width: 100%;
    }
    &--inline {
      width: 100%;
      @extend %flex-center;
      .delivery-period-count {
        :deep() .input-group__input {
          width: 48px;
          input {
            text-align: right;
          }
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
      .delivery-period {
        flex-shrink: initial;
      }
    }
  }
}
</style>
