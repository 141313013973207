<template>
  <ez-form-modal ref="userModal" class="ez-user-modal" @close="onClose">
    <template #title>
      <slot name="title">{{ isExistingUser ? `${entity} Details` : `Add New ${entity}` }}</slot>
    </template>
    <template #content>
      <ez-form
        :key="keyId"
        ref="form"
        :formKey="formKey"
        :additional-headers="{ loadingKey }"
        :action="action"
        :method="method"
        :default-data="defaultData"
        @success="onSubmitSuccess"
        @error="onSubmitError"
      >
        <ez-input
          :formKey="formKey"
          label="Name"
          name="name"
          placeholder="Enter Full Name"
          :value="user.name"
        />
        <ez-input
          :formKey="formKey"
          label="Email Address"
          placeholder="Enter Email Address"
          name="email"
          :value="user.email"
        />
        <ez-input
          class="mt-12"
          :formKey="formKey"
          :value="user.phone || ''"
          placeholder="Enter Phone Number"
          name="phone"
          label="Phone Number"
        />
        <input v-if="shouldMirror" type="hidden" name="shouldMirror" :value="shouldMirror" />
        <slot></slot>
      </ez-form>
    </template>
    <template #footer>
      <ez-button type="link" formType="button" @click="close">Cancel</ez-button>
      <ez-button formType="button" @click="submitForm" :is-loading="isLoadingUser">
        {{ isExistingUser ? 'Update Details' : `Add ${entity}` }}
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<script>
import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form';
import { EzFormModal } from '@/components/ui/Modal';
import EzInput from '@/components/ui/Input';
import { LOADING_KEY } from '@/util/constants';
import { mapGetters } from 'vuex';
import uuid from 'uuid/v4';

export default {
  components: {
    EzButton,
    EzForm,
    EzFormModal,
    EzInput,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    formAction: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    additionalData: {
      type: Object,
      required: false,
      default: null,
    },
    entity: {
      type: String,
      required: false,
      default: 'User',
    },
    shouldMirror: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      keyId: null,
      loadingKey: LOADING_KEY.VENUE_SUPPLIER_ADD_USER,
    };
  },
  computed: {
    defaultData() {
      return { optionalPermissions: [], ...this.additionalData };
    },
    isExistingUser() {
      return this.user && !!this.user.id;
    },
    method() {
      return this.isExistingUser ? 'patch' : 'post';
    },
    action() {
      if (!this.isExistingUser) {
        return this.formAction;
      }

      return `${this.formAction}/${this.user.id}`;
    },
    ...mapGetters('loading', ['getLoading']),
    isLoadingUser() {
      return this.getLoading(LOADING_KEY.VENUE_SUPPLIER_ADD_USER);
    },
  },
  watch: {
    user(val) {
      this.user = val;
      this.keyId = uuid();
    },
  },
  methods: {
    open() {
      this.$refs.userModal.open();
    },
    close() {
      this.$refs.userModal.close();
    },
    submitForm() {
      this.$refs.form.onSubmit();
    },
    onSubmitSuccess(result) {
      this.$emit('success', { ...result, isUpdate: this.isExistingUser });
      if (!this.isExistingUser) this.$refs.form.reset();
    },
    onSubmitError(result) {
      this.$emit('error', result);
    },
    onClose() {
      this.$refs.form.clearErrors(['ezinput', 'eztextarea', 'ezdropdown', 'ezrolelist']);
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.ez-user-modal {
  :deep() .ez-dropdown {
    max-width: 100%;
  }
  :deep() .modal {
    overflow: visible;
  }
  .ez-form {
    .input-group + .input-group {
      margin-top: 0.75rem;
    }
  }
}
</style>
