<script>
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import { mapMutations } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'PrimaryContactDropdown',
  components: {
    EzSimpleDropdown,
  },
  props: {
    formKey: {
      type: String,
      required: false,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    primarySelected: {
      type: Object,
      required: false,
      default: () => {},
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select Primary Contact',
    },
  },
  data() {
    return {
      dropdownArrowUp: false,
      selected: null,
    };
  },
  computed: {
    error() {
      return this.$store.getters['errors/getError'](this.formKey, this.name);
    },
    hasErrorMessage() {
      return (
        this.error &&
        typeof this.error === 'object' &&
        Object.prototype.hasOwnProperty.call(this.error, 'message')
      );
    },
  },
  watch: {
    primarySelected(val) {
      this.selected = val.name;
      this.$emit('onSelect', val);
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERROR']),
    onDropdownOpen() {
      this.dropdownArrowUp = true;
    },
    onDropdownClose() {
      this.dropdownArrowUp = false;
    },
    onOptionSelected(option) {
      this.selected = option?.name;
      this.$emit('onSelect', option);
      this.clearErrors();
    },
    reset() {
      this.onOptionSelected(null);
    },
    clearErrors() {
      this.CLEAR_ERROR({ formKey: this.formKey, field: this.name });
    },
  },
};
</script>

<template>
  <div>
    <span class="label">{{ label }}</span>
    <ez-simple-dropdown
      :class="[
        'primary-contact',
        { 'primary-contact--open': dropdownArrowUp },
        { 'primary-contact--has-error': hasErrorMessage },
      ]"
      @open="onDropdownOpen"
      @close="onDropdownClose"
    >
      <template #display>
        {{ selected || placeholder }}
        <font-awesome-icon v-if="dropdownArrowUp" icon="angle-up" />
        <font-awesome-icon v-else icon="angle-down" />
      </template>
      <template #dropdown>
        <div>
          <ul>
            <li v-for="option in options" :key="option.id">
              <div
                @click="
                  ((option.phone && option.id) || (!option.phone && !option.id)) &&
                    onOptionSelected(option)
                "
                :class="['option', { 'option--disabled': !option.phone && option.id }]"
              >
                <div class="option__contact">
                  <div class="option__contact__name">{{ option.name }}</div>
                  <div class="option__contact__phone">{{ option.phone }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </ez-simple-dropdown>
    <div class="error" v-if="hasErrorMessage">{{ error.message }}</div>
  </div>
</template>

<style lang="scss" scoped>
.primary-contact {
  line-height: 32px;
  ul {
    @extend %ul-reset;
  }
  :deep() .ez-simple-dropdown__display-container {
    border: 2px solid rgba(255, 255, 255, 0);
  }

  &--open {
    :deep() .ez-simple-dropdown__display-container {
      border-color: $color-primary-blue;
    }
  }
  &--has-error {
    :deep() .ez-simple-dropdown__display-container {
      border: 1px solid $input-border-error-color;
    }
  }
}
:deep() .primary-contact.ez-simple-dropdown {
  width: 100%;
}
.label {
  display: block;
  margin-bottom: 6px;
  color: $color-gray-6C;
  @include font-size(12px, 14px);
  font-weight: 500;
  text-transform: capitalize;
}

.option {
  padding: 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-F5;
  }

  &__contact {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    @include font-size(14px, 20px);
    color: $color-gray-25;
    margin: 8px 0;
    &__phone {
      color: $color-gray-6C;
    }
  }

  &--disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
}

.error {
  color: $input-border-error-color;
  @include font-size(12px);
  margin-top: 8px;
}
</style>
