<template>
  <div class="admin-distributor-venues">
    <div class="filters-actions">
      <ez-filter-list
        :filters="filters"
        @resetFilter="resetFilters"
        @filterUpdated="updateFilters"
      >
        <ez-input
          formKey="filters"
          label="search"
          name="term"
          class="search"
          :placeholder="`Search for an ${$t('global.venue')}`"
          :data-cy="``"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>
    </div>
    <ez-table
      :data="venues"
      :columns="['name', 'status', 'actions']"
      :columnProps="{
        actions: { class: 'actions-cell' }
      }"
      disable-hover
    >
      <template #cell-name="{ row }">
        <v-venue-entity-info :venue="row" />
      </template>
      <template #cell-status="{ row }">
        <status-badge :status="row.status || 'draft'"></status-badge>
      </template>
      <template #cell-actions="{ row }">
        <ez-toggle
          :isActive="row.isConnected"
          @toggleState="toggleDistributorConnect($event, row)"
        >
          <template #active>Connected</template>
          <template #inactive>Connect</template>
        </ez-toggle>
      </template>
    </ez-table>

    <ez-loader :show="isLoading">Loading...</ez-loader>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="fetchMoreDistributorVenues" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import EzToggle from '@/components/ui/Toggle';
import EzTable from '@/components/ui/Table';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import StatusBadge from '@/views/common/status-badge';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import EzInput from '@/components/ui/Input/EzInput.vue';
import { debounce } from '@/util/utils';

export default {
  components: {
    EzInput,
    EzFilterList,
    VVenueEntityInfo,
    StatusBadge,
    EzTable,
    EzToggle,
    EzSpinner,
    EzLoadMore,
    EzLoader,
  },
  props: {
    distributorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      venues: [],
      meta: {},
      filters: {
        term: null,
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR_VENUES,
        LOADING_KEY.ADMIN_CONNECT_DISTRIBUTOR_WITH_VENUE,
        LOADING_KEY.ADMIN_DISCONNECT_DISTRIBUTOR_FROM_VENUE,
      ]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ADMIN_DISTRIBUTOR_VENUES);
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'fetchAdminDistributorVenues',
      'connectDistributorWithVenue',
      'disconnectDistributorWithVenue',
    ]),
    async fetchDistributorVenues(loadingKey = LOADING_KEY.FETCH_ADMIN_DISTRIBUTOR_VENUES) {
      const query = {
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        limit: 20,
        ...this.filters,
      };
      const { data } = await this.fetchAdminDistributorVenues({ distributorId: this.distributorId, query, loadingKey });
      this.venues = [...this.venues, ...data.data];
      this.meta = { ...this.meta, ...data.meta };
    },
    async fetchMoreDistributorVenues() {
      await this.fetchDistributorVenues(LOADING_KEY.FETCH_MORE_ADMIN_DISTRIBUTOR_VENUES);
    },
    async toggleDistributorConnect(connected, venue) {
      const { distributorId } = this;

      if (connected) await this.disconnectDistributorWithVenue({ distributorId, venueId: venue.id });
      else await this.connectDistributorWithVenue({ distributorId, venueId: venue.id });

      this.resetData();
      await this.fetchDistributorVenues();
    },
    resetData() {
      this.venues = [];
      this.meta = {};
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'term') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      } else {
        this.filters = { ...this.filters, [filterName]: event.id };
      }

      this.resetData();
      await this.fetchDistributorVenues();
    }, 300),
    async resetFilters() {
      Object.keys(this.filters).forEach((key) => { this.filters[key] = null; });
      this.resetData();
      await this.fetchDistributorVenues();
    },
  },
  async mounted() {
    await this.fetchDistributorVenues();
  },
};
</script>

<style scoped lang="scss">
$table-border-radius: .6em;
$not-listed-text-color: #252631;
$not-listed-border-color: rgba(117, 128, 141, 0.2);

.admin-distributor-venues {
  @include font-size(14px);
  display: flex;
  flex-direction: column;

  .filters-actions {
    width: 100%;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  .table {
    tr:first-child {
      td:first-child {
        border-top-left-radius: $table-border-radius;
      }

      td:last-child {
        border-top-right-radius: $table-border-radius;
      }
    }
    .actions-cell {
      width: 10rem;
      text-align: right;
    }
    .ez-toggle {
      margin-left: auto;
      max-width: 7.25rem;
    }
  }
}
</style>
