<template>
  <div class="ez-toggle">
    <button
      type="button"
      :class="{
        'ez-toggle__button': true,
        'ez-toggle__button--active': state,
        'ez-toggle__button--disabled': disabled,
      }"
      @click="toggleState"
      :data-cy="dataCy"
      :disabled="disabled"
    >
      <span v-if="state">
        <font-awesome-icon icon="check"/>
        <slot name="active">Active</slot>
      </span>
      <slot name="inactive" v-else>Inactive</slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    onActivate: {
      type: Function,
      required: false,
      default: () => true,
    },
    onDeactivate: {
      type: Function,
      required: false,
      default: () => true,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      state: this.isActive,
    };
  },
  watch: {
    isActive(newVal) {
      this.state = newVal;
    },
  },
  methods: {
    toggleState() {
      this.$emit('toggleState', this.state);
    },
  },
};
</script>


<style scoped lang="scss">
  $active-background-color: #1BA975;
  $active-background-color-hover: #18996A;
  $active-background-color-active: #1BA975;
  $active-text-color: #FFFFFF;

  $background-color: #F5F6FA;
  $background-color-hover: #E9EBF2;
  $background-color-active: #F5F6FA;
  $text-color: #8790A3;

  $border-radius: .1875rem;

  .ez-toggle {
    display: flex;
    height: 1.75rem;
    &__button {
      @include font-size(14px);
      font-weight: 500;
      padding: 0 .75rem;
      flex: 1 1 auto;
      border: 0;
      border-radius: $border-radius;
      background-color: $background-color;
      color: $text-color;
      &:not(.ez-toggle__button--disabled):hover {
        cursor: pointer;
        background-color: $background-color-hover;
      }
      &--active {
        color: $active-text-color;
        background-color: $active-background-color;
        &:not(.ez-toggle__button--disabled):hover {
          background-color: $active-background-color-hover;
        }
        &:active {
          background-color: $active-background-color-active;
        }
      }
      svg {
        @include font-size(12px);
        margin-right: .5rem;
      }
    }
  }
</style>
