<template>
  <ez-confirmation-modal ref="deleteModal" type="red">
    <template #title>Remove User?</template>
    <template #content>
      <slot>
        <p v-if="role !== ''">When deleted, this user will no longer
          be a part of this {{ $t(`global.${role}`) | lowercase }}.</p>
        <p v-else>When deleted, this user will no longer
          be a part of this account.
        </p>
      </slot>
    </template>
    <template #footer>
      <ez-button @click="close" type="link">Cancel</ez-button>
      <ez-button :is-loading="isRemoving" @click="confirmDelete" type="red">Remove User</ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import EzButton from '@/components/ui/Button';
import { EzConfirmationModal } from '@/components/ui/Modal';
import { LOADING_KEY } from '@/util/constants';
import { mapGetters } from 'vuex';

export default {
  components: {
    EzButton,
    EzConfirmationModal,
  },
  props: {
    role: {
      required: false,
      type: String,
      default: '',
      validator: value => [
        '',
        'distributor',
        'venue',
      ].indexOf(value) !== -1,
    },
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isRemoving() {
      return this.getLoading(LOADING_KEY.REMOVE_USER);
    },
  },
  methods: {
    confirmDelete() {
      this.close();
      this.$emit('confirmDelete');
    },
    open() {
      this.$refs.deleteModal.open();
    },
    close() {
      this.$refs.deleteModal.close();
    },
  },
};
</script>
