var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ez-wizard',{attrs:{"fullWidth":false},on:{"exit":_vm.onWizardExit,"back":_vm.goToPreviousStep},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Add New Product")]},proxy:true},{key:"nav",fn:function(){return [_c('ul',[_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.PRODUCT_STEP_1,
        'ez-step-completed': _vm.currentStep > _vm.PRODUCT_STEP_1,
      }},[_vm._v(" 1. Product Info ")]),_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.PRODUCT_STEP_2,
        'ez-step-completed': _vm.currentStep > _vm.PRODUCT_STEP_2,
      }},[_vm._v(" 2. Tier Pricing ")]),(_vm.warehouses.length)?_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.PRODUCT_STEP_3,
        'ez-step-completed': _vm.currentStep > _vm.PRODUCT_STEP_3,
      }},[_vm._v(" 3. Par Level ")]):_vm._e()])]},proxy:true},{key:"actions",fn:function(){return [_c('ez-button',{attrs:{"disabled":_vm.nextDisabled,"data-cy":_vm.supplierCy.PRODUCTS.NEW_PRODUCT.BUTTON__NEXT_STEP},on:{"click":_vm.goToNextStep}},[_vm._v(" "+_vm._s(_vm.nextCta)+" ")])]},proxy:true},{key:"prevStep",fn:function(){return [(_vm.currentStep === _vm.PRODUCT_STEP_2)?_c('ez-button',{attrs:{"type":"link","formType":"button"},on:{"click":_vm.goToPreviousStep}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left","data-cy":_vm.supplierCy.PRODUCTS.NEW_PRODUCT.BUTTON__PREV_STEP}}),_c('span',[_vm._v("Back")])],1):_vm._e()]},proxy:true},{key:"pageTitle",fn:function(){return [_vm._v(_vm._s(_vm.currentStepPageTitle))]},proxy:true},{key:"pageInfo",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.currentStepPageInfo)}})]},proxy:true}])},[_c(_vm.currentStepComponent,{tag:"component",attrs:{"distributorId":_vm.distributorId,"warehouses":_vm.warehouses},on:{"stepCompleted":_vm.onStepCompleted,"stepBack":_vm.onStepBack}}),_c('ez-loader',{attrs:{"show":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.loadingMsg)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }