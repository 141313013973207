<template>
  <modal-with-tabs
    ref="userModal"
    :formKey="formKey"
    :formAction="formAction"
    :user="selectedUser"
    :venues="venues"
    :roles="roles"
    platform="venue"
    @success="onSubmitSuccess"
    @close="reset"
  >
  </modal-with-tabs>
</template>

<script>
import User from '@/models/User';
import Venue from '@/models/Venue';
import flash from '@/components/ui/FlashMessage';
import ModalWithTabs from '@/views/common/users/ModalWithTabs';

export default {
  components: {
    ModalWithTabs,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    user: {
      type: Object,
    },
    userId: {
      type: Number,
    },
    roles: {
      type: Array,
    },
  },
  data() {
    return {
      formKey: 'venue-user',
      allowGroupPermissionsInternal: false,
    };
  },
  computed: {
    formAction() {
      const isAdmin = localStorage.getItem('role') === 'admin';

      if (isAdmin) {
        return `/admin/venue-groups/${this.groupId}/users`;
      }

      return '/venue-group/users';
    },
    venues() {
      const venues = Venue.query().where('groupId', this.groupId).all();
      return [...venues];
    },
    selectedUser() {
      const role = this.roles[0] ? { key: this.roles[0].id, ...this.roles[0] } : { key: null };
      const defaultUser = {
        role,
        venue: {},
      };

      return this.user || User.query().with('venue').find(this.userId) || defaultUser;
    },
    allowGroupPermissions: {
      get() {
        return this.allowGroupPermissionsInternal;
      },
      set(value) {
        this.allowGroupPermissionsInternal = value;
      },
    },
  },
  methods: {
    open() {
      this.$refs.userModal.open();
    },
    close() {
      this.$refs.userModal.close();
    },
    reset() {
      this.$emit('close');
      this.$refs.roles.reset();
    },
    onSubmitSuccess({ data, isUpdate }) {
      User.insertOrUpdate({ data: { ...data.data, groupId: this.groupId } });
      flash.success({ title: `User successfully ${isUpdate ? 'updated' : 'added'}.` });
      this.close();
      this.$emit('success');
    },
  },
  watch: {
    user(val) {
      this.user = val;
    },
  },
};
</script>

<style scoped lang="scss">
.user-permissions {
  @include separator();

  .ez-dropdown + .ez-dropdown {
    margin-top: 0.75rem;
  }

  .ez-checkbox {
    margin: 1rem 0;
  }
}
</style>
