<template>
  <div :class="['input-group ez-on-off', { disabled }, { error }]">
    <label :for="id" :data-cy="dataCy">
      <input
        :id="id"
        :name="name"
        :value="value"
        :disabled="disabled"
        :required="required"
        type="checkbox"
        :checked="checked"
        class="input-group__input"
        @change="onChange"/>
      <span class="ez-on-off__indicator"></span>
    </label>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import { mapGetters } from 'vuex';

export default {
  props: {
    /**
     * Key from the parent form
     */
    formKey: {
      required: true,
      type: String,
    },
    /**
     * Input name
     */
    name: {
      required: true,
      type: String,
    },
    /**
     * Input value
     */
    value: {
      required: false,
      type: String,
    },
    /**
     * Label that is above the input.
     */
    label: {
      required: false,
      type: String,
    },
    /**
     * Checked state
     */
    checked: {
      required: false,
      default: false,
      type: Boolean,
    },
    /**
     * If the input is disable or not.
     */
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If the input is required or not.
     */
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    error() {
      return this.getError();
    },
    ...mapGetters('errors', ['getError']),
  },
  methods: {
    onChange(e) {
      this.$emit('change', e.target.checked);
    },
  },
  created() {
    this.id = uuid();
  },
};
</script>

<style scoped lang="scss">
$background-width: 2.25rem;
$background-height: 1.25rem;
$background-padding: .125rem;

$indicator-size: 1rem;
$indicator-transition: $background-width - $indicator-size - 2 * $background-padding;

.ez-on-off {
  &__indicator {
    @extend %flex-center;
    position: relative;
    cursor: pointer;
    width: $background-width;
    height: $background-height;
    background-color: rgba($color-gray-6C, .24);
    border-radius: .625rem;
    padding: $background-padding;
    transition: .4s;
    &:before {
      align-self: center;
      content: '';
      width: $indicator-size;
      height: $indicator-size;
      display: inline-block;
      background-color: $color-white;
      border-radius: 50%;
      transition: .4s;
    }
  }
  input {
    display: none;
    position: absolute;
    left: -999999;
    &:checked{
      + .ez-on-off__indicator {
        background-color: $color-primary-green;
        &:before {
          transform: translateX($indicator-transition);
        }
      }
    }
  }
  label {
    display: inline-block;
  }
}
.disabled {
  opacity: .6;
  label {
    cursor: not-allowed;
    input, .ez-on-off__indicator {
      cursor: not-allowed;
    }
  }
}
</style>
