<template>
  <div>
    <ez-dropdown
      class="role-dropdown"
      ref="dropdown"
      :form-key="formKey"
      name="roleKey"
      label="Role"
      :data="roles"
      :selected="selectedRoleId"
      placeholder="Choose Role"
      @change="onRoleChange"
    />
    <div class="is-manager-wrapper" v-if="isManager">
      <ez-checkbox
        :form-key="formKey"
        :checked="allowGroupPermissions"
        class="ez-checkbox"
        label="Allow Group Permissions"
        name="group"
        @change="
          val => {
            allowGroupPermissions = val;
            $emit('groupPermissionChange', val);
          }
        "
      />
      <font-awesome-icon
        v-tooltip.bottom="{
          content: `Gives an ability to manage
        multiple ${$t('global.venues').toLowerCase()}.`,
          classes: ['tooltip-general'],
        }"
        icon="info-circle"
      />
    </div>
    <div v-if="hasAccRepresentative">
      <ez-dropdown
        class="mt-12"
        label="New Account Representative"
        :data="availableAccountReps"
        :form-key="formKey"
        name="replacementOwnerId"
        is-full-width
      />
      <p class="new-acc-info">
        Changing to this role means that this user won’t be able to be an account representative
        anymore. You can assign their accounts to another user or leave them unassigned.
      </p>
    </div>
    <div v-if="hasAdditionalPermissions && !detachPermissions" class="ez-permissions">
      <div class="ez-permissions__label">Permissions</div>
      <ul>
        <li v-for="permission in additionalPermissions" :key="permission.id">
          <div
            :class="['ez-permissions__permission', { 'extra-perm': permission.parentKey }]"
            v-if="!permission.parentKey || userPermissionsCopy[permission.parentKey]"
          >
            <div class="ez-permissions__label-container">
              <span>{{ permission.name }}</span>
              <small>{{ permission.description }}</small>
            </div>
            <ez-on-off
              :formKey="formKey"
              :checked="isExistingUser ? !!userPermissions[permission.key] : permission.default"
              :value="permission.key"
              @change="ev => changePermission(ev, permission)"
              name="optionalPermissions"
            />
          </div>
        </li>
      </ul>
    </div>
    <ez-dropdown
      v-if="needsVenue && !detachPermissions"
      :class="{ 'role-dropdown': !isManager }"
      :form-key="formKey"
      :data="venues"
      :placeholder="`Choose ${$t('global.venue')}`"
      :readonly="isManager && allowGroupPermissions"
      :selected="selectedUserVenueId"
      name="venueId"
      :label="$t('global.venue')"
    />

    <div v-if="hasRolePermissions">
      <hr :class="{ 'mb-0': !hasPurchaseLimitPermission && detachPermissions }" />
      <ez-input
        v-if="hasPurchaseLimitPermission"
        type="number"
        class="form-item__limit"
        label="Purchase Limit"
        placeholder="Set purchasing limit"
        :formKey="formKey"
        ref="limitInput"
        name="purchaseLimit"
        :value="user.purchaseLimit"
      >
        <template #prefix>
          {{ currencySymbol }}
        </template>
        <template #help
          >Approval limits are up to but not including the inputted number and $0 means every order
          requires approval.</template
        >
      </ez-input>
    </div>
    <hr class="mb-0" v-if="hasPurchaseLimitPermission && detachPermissions" />
  </div>
</template>

<script>
import EzDropdown from '@/components/ui/Dropdown';
import EzOnOff from '@/components/ui/OnOff';
import EzCheckbox from '@/components/ui/Checkbox';
import EzInput from '@/components/ui/Input';
import { clone, getCurrency } from '@/util/utils';
import Group from '@/models/Group';
import { CAN_BE_ACC_OWNER, UNASSIGNED_ACCOUNT } from '@/util/constants';
import VuePermission from '@/mixins/permissions';

export default {
  name: 'ezrolelist',
  components: {
    EzCheckbox,
    EzDropdown,
    EzOnOff,
    EzInput,
  },
  props: {
    roles: {
      required: true,
      type: Array,
    },
    formKey: {
      required: true,
      type: String,
    },
    user: {
      required: true,
      type: Object,
    },
    venues: {
      required: false,
      type: Array,
      default: () => [],
    },
    groupId: {
      type: Number,
      required: false,
      default: 0,
    },
    users: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * This is used for Outlet//Group Settings//Users
     * In that modal, role and permissions are separated into two tabs
     */
    detachPermissions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedRoleId: null,
      selectedRole: null,
      allowGroupPermissions: false,
      userPermissionsCopy: {},
      hasAccRepresentative: false,
      unassignedAccount: UNASSIGNED_ACCOUNT,
    };
  },
  computed: {
    group() {
      return Group.query().find(this.groupId) || {};
    },
    currencySymbol() {
      const { symbol } = (this.group && this.group.currency) || getCurrency() || {};
      return symbol;
    },
    isExistingUser() {
      return this.user && !!this.user.id;
    },
    additionalPermissions() {
      const permissions = (this.selectedRole || {}).optionalPermissions || [];
      const isUserPermissionsCopyEmpty = !Object.values(this.userPermissionsCopy).length;
      if (isUserPermissionsCopyEmpty) {
        permissions.forEach(per => {
          this.changePermission(per.default, per);
        });
      }
      return permissions.filter(p => !p.parentKey || this.userPermissionsCopy[p.parentKey]);
    },
    rolePermissions() {
      return (this.selectedRole || {}).rolePermissions;
    },
    hasAdditionalPermissions() {
      return (this.additionalPermissions || []).length > 0;
    },
    hasRolePermissions() {
      return (this.rolePermissions || []).length > 0;
    },
    hasPurchaseLimitPermission() {
      return this.hasRolePermissions
        ? this.rolePermissions.includes('venue_can_have_purchase_limit')
        : false;
    },
    userPermissions() {
      return this.user.permissions || {};
    },
    needsVenue() {
      return [
        'venue_manager',
        'venue_orderer',
        'venue_receiver',
        'venue_finance',
        'venue_subscriber',
      ].includes(this.selectedRoleId);
    },
    selectedUserVenueId() {
      if (this.allowGroupPermissions) {
        return null;
      }
      return this.user.venue && this.user.venue.id ? this.user.venue.id : null;
    },
    isManager() {
      return this.selectedRoleId === 'venue_manager';
    },
    accountOwners() {
      return this.users.filter(user => VuePermission.checkUser(user, 'isAccountOwner'));
    },
    availableAccountReps() {
      const otherAccountReps = this.accountOwners.filter(owner => owner.id !== this.user.id);
      return [this.unassignedAccount, ...otherAccountReps];
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, {
        selectedRoleId: null,
        selectedRole: null,
        allowGroupPermissions: false,
        hasAccRepresentative: false,
      });
    },
    clearErrors() {
      this.$refs.dropdown.clearErrors();
    },
    onRoleChange(role) {
      const selectedUserCanBeAccOwner = VuePermission.checkUser(this.user, 'isAccountOwner');
      const selectedRoleIsIncludeReqPermission = role.rolePermissions.includes(CAN_BE_ACC_OWNER);
      this.hasAccRepresentative = selectedUserCanBeAccOwner && !selectedRoleIsIncludeReqPermission;
      this.selectedRoleId = role.id;
      this.allowGroupPermissions = this.user.scope === 'venue-group';
      this.selectedRole = this.roles.find(r => r.id === role.id);
      this.$emit('roleChange', this.additionalPermissions, this.selectedRole);
    },
    changePermission(ev, perm) {
      this.userPermissionsCopy[perm.key] = ev;
      this.userPermissionsCopy = clone(this.userPermissionsCopy);
    },
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler(user) {
        this.allowGroupPermissions = user.scope === 'venue-group';
        this.selectedRoleId = user.role && user.role.key ? user.role.key : null;
        this.selectedRole = this.roles.find(r => r.id === this.user.role.key);
      },
    },
    userPermissions: {
      handler() {
        const isObject = this.userPermissions && typeof this.userPermissions === 'object';
        this.userPermissionsCopy = isObject ? clone(this.userPermissions) : {};
      },
    },
  },
};
</script>

<style scoped lang="scss">
.role-dropdown {
  margin-top: 0.75rem;
  @include separator();
}
.is-manager-wrapper {
  @extend %flex-center;
  color: #6c7995;
  .ez-checkbox {
    margin: 1rem 0;
  }
  svg {
    cursor: pointer;
    margin-left: 10px;
    fill: #b9bfcd;
  }
}
.ez-permissions {
  @include separator();
  ul {
    @extend %ul-reset;
    li {
      + li {
        margin-top: 1rem;
      }
    }
  }
  &__permission {
    display: flex;
    align-self: flex-start;
    justify-content: space-between;
  }
  &__label-container {
    display: flex;
    flex-direction: column;
    span {
      @include font-size(14px, 16px);
      font-weight: 400;
      margin-bottom: 0.25rem;
    }
    small {
      @include font-size(12px, 16px);
      color: $color-gray-6C;
    }
  }
  &__label {
    @extend %gray-label;
    margin-bottom: 0.75rem;
  }
}
.extra-perm {
  padding-top: 20px;
  border-top: 1px dashed #dee1e4;
}
.new-acc-info {
  @include font-size(12px, 18px);
  color: $color-gray-6C;
  font-weight: 500;
  margin-bottom: 0;
}
</style>
