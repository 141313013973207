<template>
  <div class="ez-user-list">
    <ez-table
      :data="users"
      :headers="headers"
      :columns="columns"
      :columnProps="columnProps"
      :removeButton="isEditable"
      @removeItem="onRemoveUser"
      @rowClick="onUserClick"
      :rowCls="getRowClass"
    >
      <template #cell-name="{ row }">
        {{ row.name }}
        <status-badge
          v-tooltip="{
            content: 'Primary contact set by you',
            classes: ['tooltip-general'],
            placement: 'start',
          }"
          class="status-label"
          v-if="row.primary"
          status="primary"
        />
        <status-badge
          v-tooltip="{
            content: `Primary contact set by this ${platformName} account`,
            classes: ['tooltip-general'],
            placement: 'start',
          }"
          class="status-label"
          v-if="row.primaryByDefault && !row.primary"
          status="primary_default"
        />
      </template>
      <template #cell-role="{ row }">{{ row.role ? row.role.name : '' }} </template>
      <template #cell-type="{ row }">{{ row.type ? row.type : '' | capitalize }} </template>
      <template #cell-venue="{ row }">{{ row.venue ? row.venue.name : '-' }} </template>
      <template #cell-phone="{ row }">{{ row.phone ? row.phone : '-' }}</template>
      <template #cell-accountName="{ row }">
        {{ displayedAccountName(row) }}
      </template>
    </ez-table>
    <ez-button
      v-if="canAddNewUser"
      type="secondary"
      formType="button"
      isFullWidth
      @click="onAddUserClick"
    >
      <font-awesome-icon icon="plus" />
      <slot name="cta">Add New User</slot>
    </ez-button>
    <ez-user-remove-confirmation
      ref="removeConfirmation"
      :role="role"
      @confirmDelete="onConfirmDelete"
    >
      <template>
        <template v-if="role === 'distributor'">
          <p>When removed, this user will no longer be a part of your group.</p>
          <template v-if="showReassignDropdown()">
            <p>
              You can select another user to assign this user’s accounts to or leave them
              unassigned.
            </p>
            <p v-if="selectedUser.isPrimary">
              Since this user is set as a primary contact, you need to select another user to be the
              new primary contact once this one is removed.
            </p>
            <hr class="my-24" />
            <ez-dropdown
              label="Account Representative"
              is-full-width
              :data="availableAccountReps"
              @change="selectAccountRepresentative"
              formKey="accountRepresentative"
              name="accountRepresentative"
              isFullWidth
            />
          </template>
        </template>
        <template v-if="selectedUser.isPrimary">
          <template v-if="!showReassignDropdown()">
            <p>
              Since this user is set as a primary contact, you need to select another user to be the
              new primary contact once this one is removed.
            </p>
          </template>
          <primary-contact-dropdown
            :options="availablePrimaryContacts"
            class="mt-12"
            label="Primary Contact"
            name="replacementPrimaryUserId"
            form-key="primaryContact"
            @onSelect="primaryContactChanged"
          />
        </template>
      </template>
    </ez-user-remove-confirmation>
    <slot></slot>
  </div>
</template>

<script>
import VuePermission from '@/mixins/permissions';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import EzDropdown from '@/components/ui/Dropdown';
import { isAdmin, UNASSIGNED_ACCOUNT } from '@/util/constants';
import StatusBadge from '@/views/common/status-badge/StatusBadge';
import { mapActions } from 'vuex';
import PrimaryContactDropdown from '@/views/common/users/PrimaryContactDropdown';
import EzUserRemoveConfirmation from './EzUserRemoveConfirmation.vue';

export default {
  components: {
    EzButton,
    EzTable,
    EzUserRemoveConfirmation,
    EzDropdown,
    StatusBadge,
    PrimaryContactDropdown,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    headers: {
      type: Object,
      required: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    columnProps: {
      type: Object,
      required: false,
    },
    role: {
      required: false,
      type: String,
      default: '',
      validator: value => ['', 'distributor', 'venue'].indexOf(value) !== -1,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    venue: {
      type: Object,
      required: false,
      default: () => {},
    },
    canAddNewUser: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedUser: {},
      unassignedAccount: UNASSIGNED_ACCOUNT,
      replacementOwnerId: null,
      replacementPrimaryContactId: null,
    };
  },
  computed: {
    accountOwners() {
      return this.users.filter(user => VuePermission.checkUser(user, 'isAccountOwner'));
    },
    availableAccountReps() {
      if (this.selectedUser?.id) {
        const otherAccountReps = this.accountOwners.filter(
          owner => owner.id !== this.selectedUser?.id,
        );
        return [this.unassignedAccount, ...otherAccountReps];
      }
      return [this.unassignedAccount];
    },
    availablePrimaryContacts() {
      return this.users.filter(user => user.id !== this.selectedUser.id);
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/users', ['distributorFetchSingleUser', 'venueFetchSingleUser']),
    openConfirmationModal() {
      this.$refs.removeConfirmation.open();
    },
    closeConfirmationModal() {
      this.$refs.removeConfirmation.close();
    },
    getRowClass(row) {
      return 'owned' in row && !row.owned ? 'delete-disabled' : null;
    },
    async onRemoveUser(user) {
      if (this.role === 'distributor' && !isAdmin()) {
        const {
          data: { data },
        } = await this.distributorFetchSingleUser({ id: user?.id });
        this.selectedUser = data;
        this.openConfirmationModal();
        return;
      }
      if (this.role === 'venue' && !isAdmin()) {
        const {
          data: { data },
        } = await this.venueFetchSingleUser({ id: user?.id });
        this.selectedUser = data;
        this.openConfirmationModal();
        return;
      }
      this.selectedUser = user;
      this.openConfirmationModal();
    },
    onConfirmDelete() {
      this.$emit(
        'removeUser',
        this.selectedUser,
        this.replacementOwnerId,
        this.replacementPrimaryContactId,
      );
      this.selectedUser = {};
      this.replacementOwnerId = null;
      this.replacementPrimaryContactId = null;
      this.closeConfirmationModal();
    },
    onUserClick(user) {
      this.$emit('userClick', user);
    },
    onAddUserClick() {
      this.$emit('addUser');
    },
    selectAccountRepresentative(rep) {
      this.replacementOwnerId = rep.id;
    },
    primaryContactChanged(user) {
      this.replacementPrimaryContactId = user.id;
    },
    showReassignDropdown() {
      return (
        this.selectedUser?.id &&
        this.accountOwners.some(owner => owner.id === this.selectedUser?.id)
      );
    },
    displayedAccountName(row) {
      if (row.scope === 'venue-group') return row.accountName;
      if (row.venues.length > 1) return 'Multiple';
      if (row.venues.length === 0) return '-';
      return row.venues[0].name;
    },
  },
};
</script>

<style scoped lang="scss">
.ez-user-list {
  & > .button {
    margin-top: 0.75rem;
  }

  :deep() .table {
    td {
      height: 56px;
    }
    .venue-cell {
      text-align: right;
      width: 10rem;
    }

    .role-cell {
      width: 8rem;
    }

    .delete-disabled:hover {
      .remove-button {
        display: none;
      }
      td:last-child {
        color: $color-gray-6C;
        @include font-size(14px, 32px);
      }
    }
  }
  .status-label {
    display: inline;
    margin-left: 8px;
  }
}
</style>
