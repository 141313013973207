<script>
import VEntityInfo from '@/components/v3/elements/VEntityInfo';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import StatusBadge from '@/views/common/status-badge';
import EzLiteBadge from '@/components/ui/LiteBadge';

export default {
  components: {
    EzPremiumBadge,
    EzLiteBadge,
    VEntityInfo,
    StatusBadge,
  },
  props: {
    entity: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    /**
     * Override the name provided in the entity object.
     */
    name: {
      type: String,
      required: false,
    },
    showBadge: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    hasPremiumBadge() {
      return this.showBadge && this.$helpers.isPremium(this.entity.accountType);
    },
    hasLiteBadge() {
      return this.showBadge && this.$helpers.isLite(this.entity.accountType);
    },
    title() {
      return this.name ? this.name : this.entity.name;
    },
  },
};
</script>
<template>
  <v-entity-info :imageUrl="entity.logo" imageBorderRadius="50%" :imageHasBorder="true">
    <template>
      <h1>{{ title }}</h1>
    </template>
    <template #suffix>
      <div class="u-flex-center">
        <ez-premium-badge v-if="hasPremiumBadge" />
        <ez-lite-badge v-if="hasLiteBadge" />
        <status-badge :status="entity.status || 'draft'"/>
      </div>
    </template>
  </v-entity-info>
</template>
