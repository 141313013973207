<template>
  <ez-user-list
    :users="users"
    :headers="{
      email: () => 'Email Address'
    }"
    :columns="['name', 'email', 'role']"
    :columnProps="{
      role: { class: 'role-cell' },
    }"
    @userClick="onUserClick"
    @addUser="onAddUser"
    @removeUser="onRemoveUser"
  >
    <ez-distributor-user-modal
      ref="userModal"
      :roles="roles"
      :formKey="formKey"
      :distributorId="distributorId"
      :userId="selectedUserId"
      :selected-user="selectedUser"
      @submitSuccess="onSubmitSuccess"
    />
  </ez-user-list>
</template>

<script>
import { mapActions } from 'vuex';
import EzUserList, { EzDistributorUserModal } from '@/views/common/users';

export default {
  components: {
    EzUserList,
    EzDistributorUserModal,
  },
  props: {
    distributorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'distributor-user',
      selectedUserId: null,
      selectedUser: {
        role: { key: null },
        distributor: {},
        permissions: {},
      },
      users: [],
      roles: [],
    };
  },
  methods: {
    ...mapActions('entities/users', [
      'fetchAdminDistributorUsersNew',
      'removeAdminDistributorUser',
    ]),
    ...mapActions('roles', ['getAdminDistributorRoles']),
    async fetchDistributorUsers() {
      const { data } = await this.fetchAdminDistributorUsersNew({ distributorId: this.distributorId });
      this.users = data.data;
    },
    async onUserClick({ id }) {
      this.selectedUserId = id;
      this.selectedUser = this.users.find(usr => usr.id === id);
      this.openModal();
    },
    async onAddUser() {
      this.selectedUserId = -1;
      this.selectedUser = {
        role: { key: null },
        distributor: {},
        permissions: {},
      };
      this.openModal();
    },
    async onRemoveUser({ id }, replacementOwnerId) {
      await this.removeAdminDistributorUser({ distributorId: this.distributorId, id, replacementOwnerId });
      await this.fetchDistributorUsers();
    },
    async onSubmitSuccess() {
      await this.fetchDistributorUsers();
    },
    openModal() {
      this.$refs.userModal.open();
    },
    closeModal() {
      this.$refs.userModal.close();
    },
  },
  async mounted() {
    const [data] = await Promise.all([
      this.getAdminDistributorRoles(),
      this.fetchDistributorUsers(),
    ]);
    this.roles = data;
  },
};
</script>

<style scoped lang="scss">
  :deep() .table {
    .role-cell {
      text-align: right;
    }
  }
</style>
