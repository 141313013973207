<template>
  <ez-wizard
    :fullWidth="false"
    @exit="onWizardExit"
    @back="goToPreviousStep">
    <template #title>Add New Product</template>
    <template #nav>
      <ul>
        <li :class="{
          'ez-step-active': currentStep === PRODUCT_STEP_1,
          'ez-step-completed': currentStep > PRODUCT_STEP_1,
        }">
          1. Product Info
        </li>
        <li :class="{
          'ez-step-active': currentStep === PRODUCT_STEP_2,
          'ez-step-completed': currentStep > PRODUCT_STEP_2,
        }">
          2. Tier Pricing
        </li>
        <li v-if="warehouses.length" :class="{
          'ez-step-active': currentStep === PRODUCT_STEP_3,
          'ez-step-completed': currentStep > PRODUCT_STEP_3,
        }">
          3. Par Level
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button
        :disabled="nextDisabled"
        @click="goToNextStep"
        :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.BUTTON__NEXT_STEP"
      >
        {{ nextCta }}
      </ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="currentStep === PRODUCT_STEP_2"
        type="link"
        formType="button"
        @click="goToPreviousStep">
        <font-awesome-icon icon="arrow-left"
        :data-cy="supplierCy.PRODUCTS.NEW_PRODUCT.BUTTON__PREV_STEP"
      />
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>
      <div v-html="currentStepPageInfo"></div>
    </template>
    <component
      :is="currentStepComponent"
      :distributorId="distributorId"
      :warehouses="warehouses"
      @stepCompleted="onStepCompleted"
      @stepBack="onStepBack"
    />
    <ez-loader :show="isLoading">
      {{loadingMsg}}
    </ez-loader>
  </ez-wizard>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button';
import EzWizard from '@/components/layout/Wizard.vue';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { LOADING_KEY } from '@/util/constants';
import steps, {
  PRODUCT_STEP_1,
  PRODUCT_STEP_2,
  PRODUCT_STEP_3,
  PRODUCT_STEP_4,
} from './steps';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzLoader,
    EzButton,
    EzWizard,
  },
  props: {
    distributorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentStep: 0,
      PRODUCT_STEP_1,
      PRODUCT_STEP_2,
      PRODUCT_STEP_3,
      PRODUCT_STEP_4,
      loadingMsg: 'Loading...',
      nextDisabled: true,
      warehouses: [],
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.INIT_DISTRIBUTOR,
        LOADING_KEY.DELETE_DISTRIBUTOR_SPECIAL_TIER_PRICING,
        LOADING_KEY.DELETE_DISTRIBUTOR_DEFAULT_TIER_PRICING,
        LOADING_KEY.DISTRIBUTOR_UPDATE_PRODUCT,
        LOADING_KEY.DISTRIBUTOR_FETCH_WAREHOUSES,
      ]);
    },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      if (this.currentStep === PRODUCT_STEP_2 && !this.warehouses.length) return 'Go To Overview';
      return steps[this.currentStep].nextCta;
    },
    isAdmin() {
      return localStorage.getItem('role') === 'admin';
    },
    isFirstStep() {
      return this.currentStep === PRODUCT_STEP_1;
    },
    isLastStep() {
      return this.currentStep === PRODUCT_STEP_4;
    },

    disableNext() {
      return false;
    },
    ...mapGetters({
      lastValidStep: 'entities/products/getLastValidStep',
    }),
    ...mapState('entities/products', [
      'draft',
    ]),
  },
  watch: {
    currentStep(currentStep) {
      this.UPDATE_DRAFT({ currentStep });
    },
  },
  methods: {
    onWizardExit() {
      this.CLEAR_DRAFT();
      if (this.isAdmin) {
        return this.$router.push({
          name: 'admin-distributor-products',
          params: {
            id: this.distributorId,
          },
        });
      }
      return this.$router.push({ name: 'distributor-products' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= PRODUCT_STEP_1 && step <= PRODUCT_STEP_4) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= PRODUCT_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= PRODUCT_STEP_4) {
        return;
      }

      this.nextDisabled = true;

      // Skip par level step if there are no warehouses
      if (this.currentStep === PRODUCT_STEP_2 && !this.warehouses.length) {
        this.currentStep += 2;
      } else {
        this.currentStep += 1;
      }
    },
    async fetchWarehouses() {
      const { data } = await this.distributorFetchWarehouses();
      const { data: warehouses } = data;
      this.warehouses = warehouses;
    },
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    ...mapActions('entities/products', [
      'fetchDistributorProduct',
    ]),
    ...mapActions('entities/users', [
      'initDistributor',
    ]),
    ...mapMutations('entities/products', [
      'UPDATE_DRAFT',
      'CLEAR_DRAFT',
    ]),
    ...mapActions('entities/categories', [
      'fetchCategories',
    ]),
  },
  async created() {
    if (this.draft && this.draft.id) {
      this.CLEAR_DRAFT();
    }
    this.currentStep = this.draft.currentStep || PRODUCT_STEP_1;
    if (!this.isAdmin) this.fetchWarehouses();
  },
  async mounted() {
    if (!this.isAdmin) {
      await this.initDistributor();
    } else {
      await this.fetchCategories();
    }
  },
  beforeDestroy() {
    this.CLEAR_DRAFT();
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .sales-units,
  .category-dropdown {
    @include font-size(14px);
  }
  .loader {
    @extend %whole-screen;
  }
}
</style>
