<template>
  <distributor-form
    :formKey="editFormKey"
    :action="`/admin/distributors/${distributorId}`"
    :distributorId="distributorId"
    ref="createDistributorForm"
    method="patch"
    @formValid="val => (formValid = val)"
    :additional-data="{ ...primaryUser }"
    @submitSuccess="onEditFormSubmit"
    :distributor-data="distributor"
    class="distributor-info"
    hide-notification-settings
  >
    <template #primaryContact>
      <div>
        <primary-contact-dropdown
          :primary-selected="primarySelectedUser"
          :options="users"
          class="mt-12"
          label="Primary Contact"
          name="primaryUserId"
          :form-key="editFormKey"
          @onSelect="primaryContactChanged"
        />
        <div class="contact-info mt-8">
          <p class="contact-info__txt">
            User needs to have a phone number in order to be able to be set as a primary contact.
          </p>
        </div>
      </div>
    </template>
    <template #fieldsetButtons>
      <ez-button form-type="submit" :disabled="!formValid">Save Changes</ez-button>
    </template>
  </distributor-form>
</template>

<script>
import { mapActions } from 'vuex';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import PrimaryContactDropdown from '@/views/common/users/PrimaryContactDropdown';
import DistributorForm from '@/views/admin/distributors/FormAdmin.vue';

export default {
  components: {
    EzButton,
    PrimaryContactDropdown,
    DistributorForm,
  },
  props: {
    distributorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formValid: false,
      editFormKey: 'edit-distributor',
      primaryUser: {
        primaryUserId: '',
      },
      distributor: {},
      users: [],
    };
  },
  computed: {
    primarySelectedUser() {
      return this.users.find(u => u.id === this.distributor?.primaryUserId);
    },
  },
  methods: {
    ...mapActions('entities/users', ['fetchAdminDistributorUsersNew']),
    ...mapActions('entities/distributors', ['fetchAdminDistributor']),
    async fetchDistributorUsers() {
      const { data } = await this.fetchAdminDistributorUsersNew({
        distributorId: this.distributorId,
      });
      this.users = [{ id: null, name: 'Unassigned' }, ...data.data];
    },
    async fetchDistributor() {
      const { data } = await this.fetchAdminDistributor({ distributorId: this.distributorId });
      this.distributor = data.data;
    },
    async onEditFormSubmit() {
      flash.success({ title: `${this.$t('global.distributor')} successfully updated!` });
      await this.fetchDistributor();
    },
    primaryContactChanged(ev) {
      this.primaryUser.primaryUserId = ev.id;
    },
  },
  async created() {
    await Promise.all([this.fetchDistributor(), this.fetchDistributorUsers()]);
  },
};
</script>

<style scoped lang="scss">
.distributor-info {
  .button {
    margin-top: 1.5em;
  }
}
.contact-info {
  &__txt {
    @include font-size(12px);
    color: $color-gray-6C;
    margin: 0;
    line-height: px-to-rem(18px);
  }
}
</style>
