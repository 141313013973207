<template>
  <EzCategoryDropdown
    ref="select"
    :data="listedCategories"
    :name="name"
    :disabled="disabled"
    :isFullWidth="isFullWidth"
    :selected="selected"
    :is-parent-disabled="isParentDisabled"
    @change="onChange"
    :label="label"
    :class="['category-dropdown']"
    :data-cy="dataCy"
  />
</template>

<script>
import Category from '@/models/Category';
import EzCategoryDropdown from '@/components/ui/CategoryDropdown';

export default {
  name: 'ezfiltercategory',
  components: {
    EzCategoryDropdown,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    isParentDisabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Number, String],
      required: false,
    },
    label: {
      type: String,
    },
    categories: {
      required: false,
      default: null,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    listedCategories() {
      const categories = this.categories ? this.categories : Category.query()
        .with('children')
        .where('parentId', null)
        .all();

      return [
        {
          id: null,
          name: 'All Categories',
        },
        ...categories.map(({ id, name, children }) => ({
          id,
          name,
          children: (children || []).map(child => ({
            id: child.id,
            name: child.name,
          })).sort(this.sortByName),
        })).sort(this.sortByName),
      ];
    },
  },
  methods: {
    sortByName(a, b) {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      // eslint-disable-next-line
      return aName !== bName ? aName < bName ? -1 : 1 : 0;
    },
    onChange(value) {
      this.$emit('change', value);
    },
    reset() {
      this.$refs.select.reset();
    },
  },
};
</script>
