<template>
  <div
    :class="[
      'button-dropdown',
      `button-dropdown--${buttonType}`,
      { 'button-dropdown--toggle-only': !hasDefaultSlot },
      { 'button-dropdown--button-only': !showToggleIcon },
    ]"
  >
    <div class="button-dropdown__toggle-container">
      <button
        v-show="hasDefaultSlot"
        type="button"
        :class="`button button--${buttonType}`"
        @click="click"
        ref="mainButton"
        :data-cy="dataCy"
      >
        <!-- @slot Button text. -->
        <slot></slot>
      </button>
      <button
        v-show="showToggleIcon"
        ref="dropdownToggle"
        @click="toggleDropdown"
        type="button"
        :class="`button button--${buttonType} trigger drp-btn`"
        aria-haspopup="true"
        aria-expanded="false"
        :data-cy="dataCy"
        :disabled="disabled"
      >
        <span :class="[{ 'mr-4': hasIconTextSlot }, `icon-text--${buttonType}`]">
          <slot name="iconText" />
        </span>
        <slot name="icon">
          <font-awesome-icon v-if="expanded" icon="angle-up" />
          <font-awesome-icon v-else icon="angle-down" />
        </slot>
      </button>
    </div>
    <div ref="dropdown" v-show="expanded" class="button-dropdown__dropdown" :style="dropdownStyle">
      <!-- @slot Dropdown list, can accept `a` or `button`. -->
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expandOnClick: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'primary',
    },
    showToggleIcon: {
      type: Boolean,
      default: true,
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
      dropdownStyle: {
        top: 0,
      },
    };
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    hasIconTextSlot() {
      return !!this.$slots.iconText;
    },
  },
  methods: {
    click() {
      if (this.expandOnClick) {
        this.toggleDropdown();
      }
      /**
       * When a user click the main button.
       * @event click
       */
      this.$emit('click');
    },
    toggleDropdown() {
      this.expanded = !this.expanded;
      this.$nextTick(() => this.setDropdownPosition(this.$el, this.$refs.dropdown));
    },
    setDropdownPosition($container, $dropdown) {
      const dropdownOffset = 4;
      const totalHeight = $container.offsetTop + $container.clientHeight + $dropdown.clientHeight;
      const showAbove = totalHeight > window.innerHeight;

      this.dropdownStyle.top = showAbove
        ? `${-($dropdown.clientHeight + dropdownOffset)}px`
        : `${$container.clientHeight + dropdownOffset}px`;
    },
    documentClick(e) {
      const { mainButton, dropdownToggle } = this.$refs;

      if (!mainButton.contains(e.target) && !dropdownToggle.contains(e.target)) {
        this.expanded = false;
      }

      if (this.expandOnClick) {
        return;
      }

      if (!dropdownToggle.contains(e.target)) {
        this.expanded = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.documentClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
$border-radius: 0.1875rem;

.button-dropdown {
  position: relative;
  display: inline-flex;
  align-items: stretch;
  border-radius: $border-radius;

  .trigger svg {
    margin-right: 0;
  }

  &--secondary {
    background-color: $button-secondary-bg;

    &:hover {
      background-color: $button-secondary-hover-bg;
    }

    &:active {
      background-color: $button-secondary-bg;
    }
  }

  &__dropdown {
    @include absolute(top 100% right 0);
    min-width: 100%;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.12);
    border: 1px solid #dee1e4;
    border-radius: $border-radius;
    background-color: #fff;
    color: #212529;
    z-index: 17;
    overflow: hidden;
    padding: 0.25rem 0;

    hr {
      margin: 0;
      border: 0;
      border-top: 1px solid #d8dde3;
    }

    > a,
    > button {
      @extend %flex-center;
      width: 100%;
      will-change: background-color;
      transition: background-color 0.3s ease-in-out;
      text-decoration: none;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;

      &:hover {
        background-color: $color-gray-F5;
      }
    }
  }

  &__toggle-container {
    @extend %flex-center;
    border-radius: $border-radius;

    .button {
      @extend %base-button;
      min-height: 36px;
      min-width: 36px;

      @include button-type(
        'primary',
        $button-primary-bg,
        $button-primary-color,
        $button-primary-hover-bg,
        $button-primary-bg
      ) {
        &:first-of-type {
          @include border-right-radius(0);
        }

        &:last-of-type {
          @include border-left-radius(0);
          border-left: 1px solid rgba($color-primary-blue, 0.64);
          color: rgba(#fff, 0.5);
        }
        &:disabled {
          box-shadow: none;
          background-color: $button-disabled-bg;
          color: #fff;
          cursor: not-allowed;
        }
      }

      @include button-type(
        'secondary',
        $button-secondary-bg,
        $button-secondary-color,
        $button-secondary-hover-bg,
        $button-secondary-bg
      ) {
        &:first-of-type {
          @include border-right-radius(0);
        }
        &:last-of-type {
          @include border-left-radius(0);
          border-left: 1px solid #dee1e4;
          color: rgba($color-gray-25, 0.5);
        }
      }
    }
  }

  &--toggle-only {
    .button-dropdown__toggle-container {
      .button {
        border-radius: $border-radius;
        border-left: 0;
      }
    }
  }
  &--button-only {
    .button-dropdown__toggle-container {
      .button {
        border-radius: $border-radius;
      }
    }
  }
}

.icon-text--primary {
  color: $button-primary-color;
}

.icon-text--secondary {
  color: $button-secondary-color;
}
</style>
