<script>
import EzOnOff from '@/components/ui/OnOff/EzOnOff';

/**
 *
 * @version 1.0.0
 * @since 3.5.0
 */
export default {
  name: 'OrderNotificationToggle',
  components: {
    EzOnOff,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      notificationSettings: {
        email: false,
        whatsApp: false,
        sms: false,
        ...this.user?.notificationSettings,
      },
    };
  },
  methods: {
    setValue(val, name) {
      this.notificationSettings[name] = val;
      this.$emit('change', this.notificationSettings);
    },
    reset() {
      this.notificationSettings = {
        email: false,
        whatsApp: false,
        sms: false,
        ...this.user?.notificationSettings,
      };
    },
  },
};
</script>

<template>
  <div class="ez-permissions mt-16">
    <div class="ez-permissions__label">Receive Order Notifications Via</div>
    <ul>
      <li>
        <div class="ez-permissions__permission">
          <div class="ez-permissions__label-container">
            <span>Email Address</span>
            <small>Receive an email on the email address provided for this <br> user.</small>
          </div>
          <ez-on-off
            :formKey="formKey"
            :checked="notificationSettings.email"
            @change="(ev) => setValue(ev, 'email')"
            name="notificationSettings"/>
        </div>
      </li>
      <li>
        <div class="ez-permissions__permission">
          <div class="ez-permissions__label-container">
            <span>SMS</span>
            <small>Receive a text message on the phone number provided for <br> this user.</small>
          </div>
          <ez-on-off
            :formKey="formKey"
            :checked="notificationSettings.sms"
            @change="(ev) => setValue(ev, 'sms')"
            name="notificationSettings"/>
        </div>
      </li>
      <li>
        <div class="ez-permissions__permission">
          <div class="ez-permissions__label-container">
            <span>WhatsApp</span>
            <small>Receive a WhatsApp message on the phone number <br>
              provided for this user.</small>
          </div>
          <ez-on-off
            :formKey="formKey"
            :checked="notificationSettings.whatsApp"
            @change="(ev) => setValue(ev, 'whatsApp')"
            name="notificationSettings"/>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.ez-permissions {
  ul {
    @extend %ul-reset;
    li {
      + li {
        margin-top: 1rem;
      }
    }
  }
  &__permission {
    display: flex;
    align-self: flex-start;
    justify-content: space-between;
  }
  &__label-container {
    display: flex;
    flex-direction: column;
    span {
      @include font-size(14px, 16px);
      font-weight: 400;
      margin-bottom: .25rem;
    }
    small {
      @include font-size(12px, 16px);
      color: $color-gray-6C;
    }
  }
  &__label {
    @extend %gray-label;
    margin-bottom: .75rem;
  }
}
</style>
