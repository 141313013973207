import i18n from '@/i18n';

export const DISTRIBUTOR_STEP_1 = 0;
export const DISTRIBUTOR_STEP_2 = 1;
export const DISTRIBUTOR_STEP_3 = 2;

const steps = {
  [DISTRIBUTOR_STEP_1]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/distributors/new-distributor/Info.vue'),
    title: 'Create Supplier - Info',
    pageTitle: 'Please add main info',
    pageInfo: `Add ${i18n.t('global.distributor').toLowerCase()}’s logo, it’s name, contact info and addresses of the business that will be used throughout the platform.`,
    nextCta: 'Next Step',
  },
  [DISTRIBUTOR_STEP_2]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/distributors/new-distributor/Users.vue'),
    title: 'Create Supplier - Users',
    pageTitle: 'And now add users',
    pageInfo: `Add users that are going to be within this ${i18n.t('global.distributor').toLowerCase()} and assign their roles.`,
    nextCta: 'Go to Overview',
  },
  [DISTRIBUTOR_STEP_3]: {
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/distributors/new-distributor/Overview.vue'),
    title: 'Create Supplier - Overview',
    pageTitle: 'Overview',
    pageInfo: `Before you complete adding a new ${i18n.t('global.distributor').toLowerCase()}, check the information below to make sure you’ve entered everything correctly.`,
    nextCta: 'Finish',
  },
};

export default steps;
