<template>
  <ez-form
    :action="action"
    :formKey="formKey"
    :loadingKey="loadingKey"
    :transformer="transformer"
    :method="method"
    :additional-data="{
      ...(imgModified && { logo: '' }),
      ...additionalData,
    }"
    submitType="multipart"
    @success="onSubmitSuccess"
    @error="onSubmitError"
    ref="distributorForm"
    class="distributor-form"
  >
    <ez-image-upload
      :formKey="formKey"
      :isRound="true"
      :previewUrl="distributorCopy.logo || defaultImage"
      :isDefaultImage="distributorCopy.isDefaultImage"
      :columnMode="avatarColumnMode"
      @change="imgModified = true"
      name="logo"
      @removeImage="onRemoveImage(distributorCopy)"
    >
      <template #addImage>
        <span v-if="isExistingDistributor"> Change {{ $t('global.distributor') }} Logo</span>
        <span v-else>Add {{ $t('global.distributor') }} Logo</span>
      </template>
      <template #removeImage>
        <font-awesome-icon icon="times" />
        <span>Remove Logo</span>
      </template>
    </ez-image-upload>
    <ez-input
      :formKey="formKey"
      :value="name"
      @onChange="updateFormState('name', $event)"
      validators="required"
      :label="`${$t('global.distributor')} name*`"
      :placeholder="`${$t('global.distributor')} name`"
      name="name"
    />

    <ez-input
      :formKey="formKey"
      :value="customInfo"
      name="customInfo"
      label="Additional Info"
      placeholder="Additional Info"
      class="mt-12"
    />

    <ez-drop-down
      v-if="isAdmin"
      :data="distributorAccountType"
      :form-key="formKey"
      is-full-width
      name="accountType"
      :label="$t('global.accountType')"
      :selected="distributor.accountType"
      class="mt-12"
    />

    <slot name="primaryContact" />

    <hr class="address-separator" />

    <ez-address-form
      :fieldValidators="requiredAddressFields"
      :formKey="formKey"
      :address="distributor.address || {}"
      name="address"
    />

    <hr class="address-separtor" />

    <div class="form-help">
      <h4>Minimum order amount</h4>
      <p>Delivery charges will apply if order amount is below set value.</p>
    </div>

    <ez-input
      :formKey="formKey"
      :value="distributor.minimumOrderAmount"
      placeholder="Minimum order amount"
      label="Minimum order amount"
      name="minimumOrderAmount"
      type="number"
    >
      <template #prefix>$</template>
    </ez-input>

    <ez-input
      class="mt-12"
      :formKey="formKey"
      :value="distributor.deliveryFee"
      placeholder="Delivery Fee"
      label="Delivery Fee"
      name="deliveryFee"
      type="number"
    >
      <template #prefix>$</template>
    </ez-input>

    <hr class="address-separator" />

    <div class="form-help" v-if="!hideCurrencySection">
      <h4>Currency</h4>
      <p>
        Select the default currency for this {{ $t('global.distributor') | lowercase }}. Only
        {{ $t('global.venues') | lowercase }} with the same default currency will be available to
        connect to.
      </p>
    </div>

    <v-select-search
      v-if="!hideCurrencySection"
      class="width-100"
      align-left
      is-full-width
      name="currencyId"
      label="Currency"
      :key="distributor.currency && distributor.currency.id"
      :disable="isCurrencySelectDisabled"
      :data="currencyOptions"
      value-property="id"
      :placeholder="$t('global.currency')"
      :searchPlaceholder="`Select ${$t('global.currency')}`"
      :selected="distributor.currency"
      :has-clear="false"
      @selected="onChangeCurrency"
    >
      <template #result="{ result }">
        <span>
          {{ `${result.name} - ${result.symbol}` }}
        </span>
      </template>
    </v-select-search>

    <hr class="address-separator" v-if="!hideCurrencySection" />

    <div class="form-help" v-if="!hideTaxRateSection">
      <h4>Tax Rate Applicable</h4>
      <p>
        Tax will be added to the final order amount for the current
        {{ $t('global.distributor') | lowercase }}.
      </p>
    </div>
    <ez-input
      placeholder="Tax Rate Applicable"
      v-if="!hideTaxRateSection"
      :form-key="formKey"
      type="number"
      name="tax"
      step="any"
      :value="distributorCopy.tax"
      min="0"
    >
      <template #suffix>%</template>
    </ez-input>

    <hr class="address-separator" v-if="!hideTaxRateSection" />

    <v-cut-off-time
      :form-key="formKey"
      :distributor-data="distributorData"
      :distributor-id="distributorId"
      @cutOffTimeValid="ev => (cutOffTimeValidation = ev)"
    />

    <div class="form-help" v-if="!hideDormantNotification">
      <v-dormant-input
        class="dormant-period"
        name="dormantPeriod"
        :form-key="formKey"
        :value="dormantPeriod"
        title="Default Dormant Account Notification"
      >
        <p>
          You can set different reminders for each {{ $t('global.venues') | lowercase }}. This will
          be the default if one is not set.
          <br />
          <em>If the value is 0 the dormant account notification will be disabled.</em>
        </p>
      </v-dormant-input>
    </div>

    <hr class="address-separator" v-if="!hideDormantNotification" />

    <div class="form-help">
      <h4>Time Zone & Date Format</h4>
      <p>Set your business time zone and date format.</p>
    </div>

    <v-select-search
      v-if="timeZones.length"
      class="width-100"
      align-left
      is-full-width
      name="timezone"
      label="Time Zone"
      :data="timeZones"
      value-property="name"
      placeholder="Select Time Zone"
      searchPlaceholder="Search Time Zone"
      :selected="selectedTimeZone"
      :has-clear="false"
    />

    <ez-select
      v-if="supportedDateFormats.length"
      is-full-width
      name="dateFormat"
      label="Date Format"
      placeholder="Select Date Format"
      class="width-100 mt-12"
      :options="supportedDateFormats"
      :value="selectedDateFormat"
      @change="onDateFormatChange"
    />

    <template v-if="!hideNotificationSettings">
      <hr class="address-separator" />

      <div class="form-help">
        <h4>Order Request Notifications</h4>
        <p>
          Choose how {{ $t('global.distributor') | lowercase }} will receive notifications for new
          order requests.
        </p>
      </div>
      <ez-checkbox
        :formKey="formKey"
        :checked="emailEnabled"
        name="notification"
        label="Email"
        value="EMAIL"
        @change="value => (emailEnabled = value)"
      />
      <ez-input
        :formKey="formKey"
        :value="emailEnabled ? distributor.email : ''"
        :readonly="!emailEnabled"
        class="notification-number-input"
        placeholder="Email Address"
        label="Email"
        name="email"
      />
      <ez-checkbox
        :formKey="formKey"
        :checked="smsEnabled"
        name="notification"
        label="SMS"
        value="SMS"
        @change="value => (smsEnabled = value)"
      />
      <ez-input
        :formKey="formKey"
        :value="smsEnabled ? distributor.smsNumber : ''"
        :readonly="!smsEnabled"
        class="notification-number-input"
        placeholder="Phone Number"
        label="Sms number"
        name="smsNumber"
      />
      <ez-checkbox
        :formKey="formKey"
        :checked="whatsAppEnabled"
        name="notification"
        label="WhatsApp"
        value="whatsApp"
        @change="value => (whatsAppEnabled = value)"
      />
      <ez-input
        :formKey="formKey"
        :value="whatsAppEnabled ? distributor.whatsAppNumber : ''"
        :readonly="!whatsAppEnabled"
        class="notification-number-input"
        placeholder="Phone Number"
        label="WhatsApp number"
        name="whatsAppNumber"
      />
    </template>

    <slot name="fieldsetButtons"></slot>

    <template #buttons>
      <slot name="buttons"></slot>
    </template>
  </ez-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import VDormantInput from '@/components/v3/patterns/VDormantInput';
import EzAddressForm from '@/views/common/address';
import EzForm from '@/components/ui/Form';
import EzCheckbox from '@/components/ui/Checkbox';
import EzInput from '@/components/ui/Input';
import EzImageUpload from '@/components/ui/ImageUpload';
import EzDropDown from '@/components/ui/Dropdown';
import { falsy } from '@/util/utils';
import { distributorAccountType, isAdmin, LOADING_KEY } from '@/util/constants';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VCutOffTime from '@/components/v3/patterns/VCutOffTime';
import EzSelect from '@/components/ui/Select/EzSelect.vue';

export default {
  components: {
    VSelectSearch,
    EzAddressForm,
    EzForm,
    EzCheckbox,
    EzInput,
    EzImageUpload,
    EzDropDown,
    VDormantInput,
    VCutOffTime,
    EzSelect,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    loadingKey: {
      type: String,
      required: false,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    distributorId: {
      type: Number,
      required: false,
      default: -1,
    },
    distributorData: {
      type: Object,
      required: true,
    },
    avatarColumnMode: {
      type: Boolean,
      default: false,
    },
    prefilledName: {
      type: String,
      default: '',
    },
    isSupplier: {
      type: Boolean,
      default: false,
    },
    hideCurrencySection: {
      type: Boolean,
      default: false,
      required: false,
    },
    additionalData: {
      type: Object,
      default: () => {},
    },
    hideNotificationSettings: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideTaxRateSection: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideDormantNotification: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      distributorAccountType,
      emailEnabled: false,
      smsEnabled: false,
      timeZones: [],
      currencyOptions: [],
      isCurrencyChanged: false,
      isDateFormatChanged: false,
      whatsAppEnabled: false,
      distributorCopy: {},
      imgModified: false,
      dormantPeriod: 0,
      formValid: false,
      cutOffTimeValidation: false,
      requiredValues: {
        name: null,
      },
    };
  },
  computed: {
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapGetters('entities/users', ['isDistributor']),
    ...mapState('entities/users', ['loggedUser']),
    isCurrencySelectDisabled() {
      const { currencyChangeEnabled } = this.distributor;
      return typeof currencyChangeEnabled === 'boolean' ? !currencyChangeEnabled : false;
    },
    isAdmin() {
      return isAdmin();
    },
    defaultImage() {
      return this.getDefaultImage('distributor');
    },
    distributor() {
      return this.distributorData;
    },
    isExistingDistributor() {
      return Object.keys(this.distributor).length;
    },
    name() {
      return this.distributor.name ? this.distributor.name : this.prefilledName;
    },
    customInfo() {
      return this.distributor.customInfo;
    },
    workingHours() {
      return this.distributor.workingHours || {};
    },
    supportedDateFormats() {
      if (!this.distributor?.supportedDateFormats) return [];
      return this.distributor.supportedDateFormats.map(f => ({ id: f, name: f }));
    },
    selectedDateFormat() {
      const dateFormat = this.distributor?.dateFormat || '';
      return { id: dateFormat, name: dateFormat };
    },
    requiredAddressFields() {
      return {};
    },
    selectedTimeZone() {
      return (
        this.distributor?.timezone ||
        (!this.isAdmin
          ? this.loggedUser?.venues[0]?.timezone
          : this.timeZones.find(zone => zone.name === 'Asia/Singapore').name)
      );
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributor']),
    ...mapMutations('loading', ['SET_LOADING', 'CLEAR_LOADING']),
    onChangeCurrency(currency) {
      const distCurrency = this.distributor.currency;
      const distCurrencyId = distCurrency && distCurrency.id;
      this.isCurrencyChanged = currency.id !== distCurrencyId;
    },
    onDateFormatChange(dateFormat) {
      const curDateFormat = this.distributor.dateFormat;
      const newDateFormat = dateFormat.id;
      this.isDateFormatChanged = curDateFormat !== newDateFormat;
    },
    transformer(data) {
      const deliveryDays = data.getAll('deliveryDay');
      if (deliveryDays.length) {
        deliveryDays.forEach(item => data.append('deliveryDays[]', item));
      } else {
        data.append('deliveryDays', '');
      }
      return data;
    },
    submitForm() {
      this.$refs.distributorForm.onSubmit();
    },
    async onSubmitSuccess(response) {
      this.distributorCopy.isDefaultImage = true;
      if (this.isDistributor && (this.isCurrencyChanged || this.isDateFormatChanged)) {
        await this.initDistributor();
      }
      this.$emit('submitSuccess', response);
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
    },
    onRemoveImage(distributor) {
      distributor.logo = null;
      distributor.isDefaultImage = true;
      this.imgModified = true;
    },
    updateFormState(name, value) {
      this.requiredValues[name] = value;
      this.validateForm();
    },
    async initialFormState() {
      await this.$nextTick();
      this.distributorCopy = this.distributor;
      this.emailEnabled = !!this.distributor.email;
      this.smsEnabled = !!this.distributor.smsNumber;
      this.whatsAppEnabled = !!this.distributor.whatsAppNumber;
      this.dormantPeriod = this.distributor.dormantPeriod || 0;

      this.requiredValues = {
        name: this.name,
      };
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach(key => {
        if (falsy(this.requiredValues[key])) this.formValid = false;
      });
    },
    async getTimeZones() {
      await this.fetchTimeZones().then(data => {
        this.timeZones = data;
      });
    },
    ...mapActions('entities/distributors', ['removeDistributorLogo', 'fetchTimeZones']),
    ...mapActions('entities/users', ['adminFetchCurrencies', 'fetchCurrencies']),
  },
  watch: {
    async distributor(val) {
      this.distributorCopy = JSON.parse(JSON.stringify(val));
      this.emailEnabled = !!val.email;
      this.smsEnabled = !!val.smsNumber;
      this.whatsAppEnabled = !!val.whatsAppNumber;
      await this.initialFormState();
      this.validateForm();
    },
    formValid() {
      this.$emit('formValid', this.formValid && this.cutOffTimeValidation);
    },
    cutOffTimeValidation() {
      this.$emit('formValid', this.formValid && this.cutOffTimeValidation);
    },
  },
  async created() {
    this.SET_LOADING({ [LOADING_KEY.JOKER]: true });

    const {
      data: { data: currencyOptions },
    } = isAdmin() ? await this.adminFetchCurrencies() : await this.fetchCurrencies();

    this.currencyOptions = currencyOptions;

    if (this.distributorId !== -1) {
      if (!this.isSupplier) {
        this.distributorCopy = this.distributorData;
      }
      this.dormantPeriod = this.distributor.dormantPeriod || 0;
      await this.initialFormState();
      this.validateForm();
    } else {
      this.requiredValues.name = this.prefilledName;
    }
    await this.getTimeZones();

    this.CLEAR_LOADING({ key: LOADING_KEY.JOKER });
  },
};
</script>

<style scoped lang="scss">
:deep() .select-search {
  .select-search__list {
    max-height: 280px;
  }

  .select-search__item {
    height: 42px;
    display: flex;
    align-items: center;
  }
}
.distributor-form {
  max-width: 26rem;

  .ez-image-upload {
    margin-bottom: 1rem;
  }

  > .input-group + .input-group {
    margin-top: 0.75rem;
  }

  .form-buttons {
    display: flex;
  }

  .dormant-period {
    :deep() .dormant-input__title {
      font-weight: 500;
    }
  }

  .input-group + .notification-number-input.input-group {
    margin-top: 0.5em;
  }

  .notification-number-input {
    :deep() .input-group__label {
      display: none;
    }

    &.input-group + .input-group {
      margin-top: 1em;
    }
  }

  .address-separator {
    border: 0;
    border-top: 1px dashed #dee1e4;
    margin: 1.25rem 0;
  }

  .form-help {
    margin-bottom: 1rem;

    h4 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    p {
      @include font-size(12px);
      color: $color-gray-6C;
      margin: 0;
      line-height: px-to-rem(18px);
    }
  }
  .time-zone-dropdown {
    margin-top: 0.75rem;
  }

  .buttons {
    margin-top: px-to-rem(24px);
  }
  .dormant-notification-box {
    margin: 12px 0;
    p {
      @include font-size(14px);
    }

    :deep() .input-group {
      max-width: 56px;
      margin: 0 8px;
      text-align: right;
    }
  }
}
</style>
