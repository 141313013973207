<script>
/**
 * Tabs component enables tab switching
 * @version 1.0.0
 */
export default {
  props: {
    /**
     * Defines default active tab
     */
    defaultTabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabs: [],
      activeTabHash: '',
    };
  },
  methods: {
    findTab(hash) {
      let tab = null;

      if (hash) {
        tab = this.tabs.find(t => t.hash === hash);
      }

      if (this.defaultTabIndex < this.tabs.length) {
        tab = tab || this.tabs[this.defaultTabIndex];
      }

      return tab;
    },
    selectTab(hash) {
      const activeTab = this.findTab(hash);

      if (activeTab) {
        this.tabs.forEach((tab) => {
          tab.isActive = tab.hash === activeTab.hash;
        });

        this.activeTabHash = activeTab.hash;

        activeTab.$emit('tabClick');
      }
    },
  },
  render(h) {
    const { tabs, activeTabHash } = this;
    // eslint-disable-next-line dot-notation
    const items = tabs.filter(t => t.$options['_componentTag'] === 'ez-tab')
      .map((tab, key) => {
        const { title, hash, dataCy } = tab;

        let tabTitle = hash;

        if (typeof title === 'function') {
          tabTitle = title(h, { key });
        } else if (typeof title === 'string') {
          tabTitle = title;
        }

        const button = h(
          'button',
          {
            attrs: { type: 'button', ...(dataCy ? { 'data-cy': dataCy } : {}) },
            class: [
              'ez-tabs__button', { 'ez-tabs__button-active': hash === activeTabHash },
            ],
            on: {
              click: () => {
                this.selectTab(hash);
              },
            },
          },
          [
            tabTitle,
          ],
        );

        return h(
          'li',
          null,
          [button],
        );
      });

    const header = h('ul', { class: 'ez-tabs__buttons' }, items);

    const body = h('div', { class: 'ez-tabs__content' }, [this.$slots.default]);

    return h('div', { class: 'ez-tabs' }, [
      header,
      body,
    ]);
  },

  mounted() {
    this.tabs = this.$children;

    this.selectTab(window.location.hash);
  },
};

</script>

<style scoped lang="scss">
  $hover-text-color: rgba(255, 255, 255, 0.7);
  $active-text-color: #FFFFFF;

  .ez-tabs {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;

    &__buttons {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      top: 2px;
    }
    &__button {
      @include font-size(14px);
      font-weight: 400;
      color: $color-gray-6C;
      padding: .625em 0;
      margin-left: 1.5rem;
      outline: 0;
      border: 0;
      border-top: .125rem solid rgba(255, 255, 255, 0);
      border-bottom: .125rem solid rgba(255, 255, 255, 0);
      &:hover {
        cursor: pointer;
      }
      &:not(.ez-tabs__button-active):hover {
        color: $color-primary-blue;
      }
    }
    &__button-active {
      border-bottom-color: $color-primary-blue;
      color: $color-gray-25;
    }
    &__content {
      flex: 1 1 auto;
      overflow-y: auto;
      border-top: 2px solid #F3F5FA;
    }
  }
</style>
