var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ez-wizard',{attrs:{"fullWidth":_vm.currentStep === _vm.DISTRIBUTOR_STEP_2},on:{"exit":_vm.onWizardExit},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Add New "+_vm._s(_vm.$t('global.distributor')))]},proxy:true},{key:"nav",fn:function(){return [_c('ul',[_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.DISTRIBUTOR_STEP_1,
        'ez-step-completed': _vm.currentStep > _vm.DISTRIBUTOR_STEP_1,
      }},[_vm._v(" 1. "+_vm._s(_vm.$t('global.distributor'))+" Info ")]),_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.DISTRIBUTOR_STEP_2,
        'ez-step-completed': _vm.currentStep > _vm.DISTRIBUTOR_STEP_2,
      }},[_vm._v(" 2. Users ")])])]},proxy:true},{key:"actions",fn:function(){return [_c('ez-button',{attrs:{"disabled":_vm.nextDisabled},on:{"click":_vm.goToNextStep}},[_vm._v(" "+_vm._s(_vm.nextCta)+" ")])]},proxy:true},{key:"prevStep",fn:function(){return [(_vm.currentStep === _vm.DISTRIBUTOR_STEP_2)?_c('ez-button',{attrs:{"type":"link","formType":"button"},on:{"click":_vm.goToPreviousStep}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_c('span',[_vm._v("Back")])],1):_vm._e()]},proxy:true},{key:"pageTitle",fn:function(){return [_vm._v(_vm._s(_vm.currentStepPageTitle))]},proxy:true},{key:"pageInfo",fn:function(){return [_vm._v(_vm._s(_vm.currentStepPageInfo))]},proxy:true}])},[_c(_vm.currentStepComponent,{tag:"component",on:{"stepCompleted":_vm.onStepCompleted,"stepBack":_vm.onStepBack}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }