<template>
  <fieldset class="address-form">
    <legend v-if="hasLegend">
      <slot name="legend"></slot>
    </legend>
    <ez-input
      :disabled="disabled"
      :formKey="formKey"
      :name="`${name}[street]`"
      placeholder="Street address"
      :validators="fieldValidators.street ? fieldValidators.street : ''"
      :label="`Street address${fieldValidators.street ? '*' : ''}`"
      @onChange="$emit('onChange', $event)"
      :value="address.street"
      :data-cy="commonCy.ADDRESS_FORM.INPUT__STREET_ADDRESS"
    />
    <div class="address-form__city-zip">
      <ez-input
        :disabled="disabled"
        :formKey="formKey"
        :name="`${name}[city]`"
        :validators="fieldValidators.city ? fieldValidators.city : ''"
        placeholder="City"
        @onChange="$emit('city', $event)"
        :label="`City${fieldValidators.city ? '*' : ''}`"
        :value="address.city"
        :data-cy="commonCy.ADDRESS_FORM.INPUT__CITY"
      />
      <ez-input
        :disabled="disabled"
        :formKey="formKey"
        placeholder="Zip Code"
        :name="`${name}[zipCode]`"
        :validators="fieldValidators.zipCode ? fieldValidators.zipCode : ''"
        @onChange="$emit('zipCode', $event)"
        :label="`Zip code${fieldValidators.zipCode ? '*' : ''}`"
        :value="address.zipCode"
        :data-cy="commonCy.ADDRESS_FORM.INPUT__ZIP_CODE"
      />
    </div>
    <div class="address-form__city-zip">
      <ez-input
        :disabled="disabled"
        :formKey="formKey"
        placeholder="Country"
        :name="`${name}[country]`"
        :validators="fieldValidators.country ? fieldValidators.country : ''"
        @onChange="$emit('country', $event)"
        :label="`Country${fieldValidators.country ? '*' : ''}`"
        :value="address.country"
        :data-cy="commonCy.ADDRESS_FORM.INPUT__COUNTRY"
      />
      <ez-input
        :disabled="disabled"
        :formKey="formKey"
        placeholder="State"
        :name="`${name}[state]`"
        :validators="fieldValidators.state ? fieldValidators.state : ''"
        @onChange="$emit('state', $event)"
        :label="`State${fieldValidators.state ? '*' : ''}`"
        :value="address.state"
        :data-cy="commonCy.ADDRESS_FORM.INPUT__STATE"
      />
    </div>
  </fieldset>
</template>

<script>
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzInput from '@/components/ui/Input';

export default {
  components: {
    EzInput,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fieldValidators: {
      type: Object,
      required: false,
      default: () => ({
        street: 'required',
      }),
    },
  },
  data() {
    return {
      commonCy,
    };
  },
  computed: {
    hasLegend() {
      return !!this.$slots.legend;
    },
  },
  methods: {
    reset(fields = ['ezinput']) {
      this.$children
        .filter(child => fields.indexOf(child.$options.name) !== -1)
        .forEach((field) => {
          field.reset();
          field.clearErrors();
        });
    },
  },
};
</script>

<style scoped lang="scss">
  .address-form {
    border: 0;
    padding: 0;
    margin: 0;

    > .input-group:not(:first-of-type) {
      margin-top: .75rem;
    }

    &__city-zip {
      display: flex;
      margin-top: .75rem;

      .input-group {
        + .input-group {
          margin-left: .75rem;
        }

        &:first-child {
          flex: 1 0 auto;
        }

        &:last-child {
          flex-basis: 144px;
        }
      }
    }

    legend {
      @include font-size(14px);
      font-weight: 400;
      padding: 0;
      margin-bottom: 1rem;

      + .input-group {
        padding-top: 0;
      }
    }
  }
</style>
